.opc-button {
           
    
    
    
    
}

.remove .opc-compare-button:hover {
  background: #e41b13;
  opacity: 1;
  color: #fff;
}

.icon-sampleorder {
    background-image: inline-svg($op-icon-custshop-muster-svg, '#fff');
    width: 3rem;
    height: 3rem;
    margin-right:1rem;
    display: block;
}
.icon-offer {
    background-image: inline-svg($op-icon-custshop-offer-svg, '#fff');
    width: 3rem;
    height: 3rem;
    margin-right:1rem;
    display: block;
}