/*###############################################

@Title: Page Favorites
@Description:


###############################################*/

.page-favorites,
.page-multi-favorites {
    .favorites-head {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        position: relative;
        .favorites-selection {
            flex-grow: 1;
            .opc-form-field {
                margin-bottom: 0;
            }
        }
        .favorites-actions {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            #rename-form {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                display: flex;
                background: #fff;
                .opc-form-field,
                input {
                    width: 100%;
                    border: none;
                }
            }
        }
        .opc-form-field {
            background: transparent;
            margin-bottom: 0;
            .headline-select {
                width: 100%;
                border: none !important;
                position: relative;
                background: transparent;
                border-bottom: 2px solid #ccc !important;
                padding: 1rem 0 !important;
                margin-bottom: 0;
                appearance: none;
                height: auto !important;
                line-height: 1.2 !important;
                background-image: inline-svg($op-icon-angle-down-svg, '#000');
                background-position: calc(100% - 1rem) center;
                background-size: 2rem;
                background-repeat: no-repeat;
                @include fluid-type($_opc-font-min-width, $_opc-font-max-width, $_opc-font-size-h2, $_opc-font-size-h2-max);
                &::-ms-expand {
                    display: none;
                }
                option{
                    font-size: 1.6rem;
                }
            }
        }
        h4 {
            font-size: 1.4rem;
            margin-bottom: 1rem;
            padding: 0;
        }
    }
    .favorites-desc {
        margin-bottom: 4rem;
        background: #f8f8f8;
        padding: 2rem;
        h4 {
            margin-bottom: 0.5em;
        }
    }

    .opc-article-list{
        .fav-item-wrapper{
            display: flex;
            flex-wrap: wrap;
            padding: 1rem;
            border: 1px solid #ccc;
            .fav-list-item-image{
                padding-right: 2rem;
                order: 1;
                width: 100%;
                position: relative;
                @include mq($min-width: map-get($breakpoints, s)) {
                    width: auto;
                }
                img{
                    max-width: 100%;
                    max-height: 100%;
                    width: 100%;
                    @include mq($min-width: map-get($breakpoints, s)) {
                        width: auto;
                    }
                    @include mq($min-width: map-get($breakpoints, m)) {
                        max-width: 20rem;
                        max-height: 15rem;
                    }
                }
                .opc-stock{
                    position: absolute;
                    &.has-loaded{
                        bottom: 0;
                        left: .5rem;
                        padding: .5rem;
                        background: rgba(0,0,0,.5);
                        color: #fff;
                    }
                }
            }
            .fav-item-inner-wrapper{
                order: 2;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                justify-content: space-between;
                .article-list-item{
                    order: 1;
                    border: none;
                    //flex-grow: 1;
                    .article-list-item-spec{
                        > div.color{
                            >ul{display: inline;
                                float: left;
                                margin: 0;
                                padding: 0;
                                >li{
                                    float: left;
                                    max-width: 100%;
                                    list-style-type: none;
                                    padding: 0 .6rem 0 0;
                                    &::after{
                                        content: ',';
                                    }
                                    &:last-child{
                                        padding-right: 0;
                                        &::after{
                                            display: none;
                                        }
                                    }
                                }
                            }
                            &::after{
                                //display: none;
                            }
                        }
                    }
                    .article-list-item-details{
                        order: 2;
                        margin-bottom: 2rem;
                        @include mq($min-width: map-get($breakpoints, m)) {
                            order: 1;
                            margin-bottom: 1rem;
                        }
                        .article-list-item-title{
                            display: flex;
                            align-items: baseline;
                            //justify-content: space-between;
                        }
                    }
                    .article-list-item-actions{
                        order: 1;
                        width: 100%;
                        justify-content: flex-end;
                        @include mq($min-width: map-get($breakpoints, m)) {
                            order: 2;
                        }
                        @include mq($min-width: map-get($breakpoints, m)) {
                            width: 15rem;
                        }
                        .opc-compare-button {
                            background: $white;
                            &.is-active{
                                border-color: $brand-color-1;
                                color: $brand-color-1;
                            }
                        }
                    }
                }
                .article-actions{
                    order: 2;
                    //flex-grow: 1;
                    cursor: initial;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                    .opc-checkbox{
                        flex-grow: 0;
                        margin-right: 10rem;
                        margin-bottom: 1rem;
                        @include mq($min-width: map-get($breakpoints, s)) {
                            margin-bottom: 0;
                        }
                    }
                    .opc-basket-button{
                        flex-grow: 1;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        button{
                            width: 100%;
                            margin-top: 1rem;
                            @include mq($min-width: map-get($breakpoints, s)) {
                                margin-top: 0;
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}
.opc-more-button{
    &[data-op-type="icon"] {
        border-radius: 0;
    }
    .more-button-content{
        li {
            flex-basis: 100%;
            max-width: 100%;
            width: 100%;
        }
    }
}