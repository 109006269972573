/*###############################################

@Title: Content
@Description:


###############################################*/
html, body {
	margin: 0;   
	padding: 0;
	max-width: 100%;
}
html {
	font-size: 10px; 
  max-width: 100%;
  overflow-x: hidden;
  &.offcanvas-menu-open {
    overflow: hidden;
  }
}
body { 
	font-size: 1.4rem;
  font-family: $_opc-base-font-family;
  font-weight: $_opc-base-font-weight;
  line-height: $_opc-base-line-height;
	background: #efefef;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}  

.viewport {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
	//@extend %mw;
  
  @include clearfix();
	
  background: #fff;
  flex-grow: 1;

	.opc-content-loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: #000;
    opacity: 0.2;
  }
  &.orderform {
    .button-wrapper {
      display: flex;
      justify-content: flex-end;
      column-gap: 2rem;
    }
  }
} 




.result-view-menu,
.article-list-menu {
	
}
hr {
  display: block;
  unicode-bidi: isolate;
  -webkit-margin-before: 0.5em;
  -webkit-margin-after: 0.5em;
  -webkit-margin-start: auto;
  -webkit-margin-end: auto;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
  &.portlet-divider.gradient-hr{
    width: 100%;
    margin-left: calc((100% - 100vw) / 2);
    margin-right: calc((100% - 100vw) / 2);
    padding-left: calc((100vw - 100%) / 2);
    padding-right: calc((100vw - 100%) / 2);
    box-sizing: content-box;
    opacity: .1;
    border: 0;
    margin-top: 0;
    background: linear-gradient(to bottom,rgba(0,0,0,0.8),rgba(0,0,0,0.1));
    height: 8px;
    margin-bottom: -8px;
    position: relative;
    z-index: 1;
  }
  &.portlet-divider {
    width: 100%;
    margin-left: calc((100% - 100vw) / 2);
    margin-right: calc((100% - 100vw) / 2);
    padding-left: calc((100vw - 100%) / 2);
    padding-right: calc((100vw - 100%) / 2);
    box-sizing: content-box;
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
    border: 0;
    height: 1px;
    min-height: auto;
    background: black;
    opacity: .1;
  }
}
