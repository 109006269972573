/*###############################################

@Title: Hero Image Slider
@Description:
This file contains the hero image slider and other slider styles

###############################################*/

$_opc-hero-slider-mobile-height: 50vh !default;
$_opc-hero-slider-height: 50vh !default;
$_opc-hero-slider-next-icon: inline-svg($op-icon-angle-right-svg, '#333') !default;
$_opc-hero-slider-prev-icon: inline-svg($op-icon-angle-left-svg, '#333') !default;


@import "../../lib/mediaslider/src/mediaslider.scss";
// set position depending on slider-mode
.carousel-slides {
    display: flex;
    overflow: hidden;
    &.#{$tns-css-prefix}-carousel {
        >div {
            position: absolute;
        }
        .carousel-slide {
            position: relative;
            display: flex !important;
            align-items: center;
            justify-content: center;
            &::before {
                content: '';
                width: 3rem;
                height: 3rem;
                background: transparent;
                display: block;
                border-radius: 50%;
                position: absolute;
                right: 4rem;
                bottom: 4rem;
                z-index: 1;
                pointer-events: none;
                border: 2px solid transparent;
                border-top: 2px solid $brand-color-1;
                animation-name: rotater;
                animation-duration: 2s;
                animation-iteration-count: infinite;
                opacity: 1;
                transition: opacity 0.4s ease-in-out;
            }
            @keyframes rotater {
                50% {
                    transform: rotate(400deg);
                }
            }
            .carousel-slide-image,
            .carousel-slide-content {
                opacity: 0;
                transition: opacity 0.4s ease-in-out 0s;
            }
            &.op-mediaslider-item {
                &::before {}
                .carousel-slide-image,
                .carousel-slide-content {
                    opacity: 1;
                    transition-delay: 1s;
                }
            }
        }
    }
    &.#{$tns-css-prefix}-gallery {
        .carousel-slide {
            position: absolute;
        }
    }
}

// // styling vars
.opc-carousel {
    margin-bottom: 40px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    .carousel-slides {
        display: flex;
        margin-bottom: 0;
        overflow: hidden;
        height: $_opc-hero-slider-mobile-height;
        @include mq($min-width: map-get($breakpoints, m)) {
            height: $_opc-hero-slider-height;
        }
        .carousel-slide {
            height: $_opc-hero-slider-mobile-height;
            @include mq($min-width: map-get($breakpoints, m)) {
                height: $_opc-hero-slider-height;
            }
            width: 100%;
            flex-shrink: 0;
            .carousel-slide-image {
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                z-index: 1;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                opacity: 0;
                height: 50vh;
                img {
                    width: 100%;
                    display: none;
                }
            }
            .carousel-slide-content {
                position: relative;
                z-index: 2;
                display: flex;
                justify-content: center;
                height: $_opc-hero-slider-mobile-height;
                @include mq($min-width: map-get($breakpoints, m)) {
                    height: $_opc-hero-slider-height;
                }
                .carousel-slide-content-inner {
                    align-self: center;
                    text-align: center;
                }
            }
        }
    }
    .carousel-thumb-slides {
        display: none;
        margin: 0 auto;
        margin-top: 20px;
        .carousel-thumb-slide {
            margin: 0 10px;
            width: 10rem;
            opacity: 0.5;
            cursor: pointer;
            outline: none;
            .thumb-slide-image {
                img {
                    max-width: 100%;
                    transform: scale(0.9);
                    transition: transform 0.2s ease-in-out;
                }
            }
        }
    }
    .#{$tns-css-prefix}-outer {
        position: relative;
    }
    .#{$tns-css-prefix}-controls {
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 50%;
        width: 100%;
        height: 0;
        z-index: 99;
        transform: translateY(-50%);
        [data-controls="prev"],
        [data-controls="next"] {
            color: transparent;
            background-color: rgba(#fff, 0.4);
            border: none;
            font-size: 3rem;
            width: 4rem;
            height: 4rem;
            outline: none;
            display: block;
            transform: translateY(-50%);
            position: absolute;
            left: 0;
            padding: 0;
            
            &::before,
            &::after {
                background-size: 3rem;
                background-repeat: no-repeat;
                background-position: center;
                content: "";
                color: #000;
                display: block;
                width: 4rem;
                height: 4rem;
            }

            &:hover {
                background-color: rgba(#fff, 0.8);
            }
        }
        [data-controls="next"] {
            right: 0;
            left: auto;
        }
        [data-controls="prev"]::before {
            
            background-image: $_opc-hero-slider-prev-icon;

        }
        [data-controls="next"]::before {
            
            background-image: $_opc-hero-slider-next-icon;

        }
    }
    .#{$tns-css-prefix}-nav {
        padding: 1rem 0 0;
        bottom: 2rem;
        display: block;
        position: absolute;
        width: 100%;
        text-align: center;
        z-index: 99;
        button {
            margin: 0 0.3rem;
            opacity: 0.4;
            border-radius: 50%;
            background: #fff;
            width: 1.2rem;
            height: 1.2rem;
            border: 0;
            outline: none;
            &[aria-selected="true"] {
                opacity: 1;
            }
        }
    }
    &[data-op-type="slider"] {
        .carousel-slide-image {}
    }
    &[data-op-type="thumb-slider"] {
        .carousel-thumb-slides {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            [aria-selected="true"] {
                opacity: 1;
                img {
                    transform: scale(1);
                }
            }
        }
        .carousel-slide-image {}
    }
    &[data-op-type="hero-slider"] {
        .carousel-slide-image {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 50vh;
            img {
                display: none;
            }
        }
    }
}