.footer-col-categories{
    .opc-navigation[data-op-navtype="cab"] {
        >ul {
            //display: flex;
            margin-top: 3rem;
            column-count: 2;
            height: 50rem;
            &.custshop {
                display: flex;
            }
            @include mq($min-width: map-get($breakpoints, l)) {
                column-count: 4;
                height: 30rem;
            }
            >li {
                display: inline-block;
                width: 100%;
                
                &.invisible-footer {
                    display: none;
                }
                h2{
                    a{
                        color: #333;
                        font-size: 1.6rem;
                    }
                }
                
                .navigation-level-2 {
                    li {
                        width: 100%;
                        a {
                            white-space: normal;
                            line-height: 2.5rem;
                            color: #333;
                            font-size: 1.6rem;
                        }
                    }
                }
            }
        }
    }
}

.categories2 {
    width: 25%;
    position: absolute;
    top: 33rem;
    left: 50%;
    padding-left: .5rem;
    @include mq($min-width: map-get($breakpoints, l)) {
        left: auto;
        top: 14rem;
        right: 0;
        padding-left: 1rem;
    }
    .opc-navigation[data-op-navtype="cab"] {
        >ul {
            column-count: 1;
            font-size: 1.6rem;
            >li {
                h2{
                    margin: 0;
                    line-height: 2.5rem;
                    a{
                        font-size: 1.6rem;
                        color: #333;
                    }
                }
            }
        }
    }
}

.footer-info-menu{
    .opc-navigation[data-op-navtype="cab"] {
        >ul {
            //display: flex;
            >li {
                display: inline-block;
                width: 100%;
                &.invisible-footer {
                    display: none;
                }
                h2{
                    margin: 0;
                    a{
                        color: $white;
                        font-size: 1.4rem;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}