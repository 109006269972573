/*###############################################

@Title: Article Specs
@Description:
This file contains the article specs for the product details

###############################################*/

.opc-art-spec {
    width: 100%;

    .art-spec-group {
        margin-bottom: 2rem;
    }

    .art-spec-label {
        font-weight: bold;
        margin-bottom: 1rem;
        @extend .h3;
        font-weight: 400;
    }
    .art-spec-data {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2rem;
        @extend .text;
        &:last-of-type {
            margin-bottom: 0; 
        }
        dt {
            margin: 0;
            padding: 0.5rem 0;
            margin-bottom: 0.5rem;
            width: 50%;
            border-bottom: 1px solid #ccc;
            align-self: flex-start;
            font-size: inherit;
        }
        dd {
            margin: 0;
            padding: 0.5rem 0;
            margin-bottom: 0.5rem;
            width: 50%;
            border-bottom: 1px solid #ccc;
            font-size: inherit;
        }
    }
}