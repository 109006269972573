.column.full{
    padding-left: 0;
    padding-right: 0;
}
.red-line {
    height: 8px;
    background-color:   $brand-color-1;
    position: relative;
    top: 2.5em;
    display: none;
    @include mq($min-width: map-get($breakpoints, s)) {
        display: block;
    }
}

h1.red-line-title {
    text-align: center;
    line-height: 50px;
    font-weight: normal;
    position: relative;
    font-size: 32px;
    font-weight: bold;
    > span {
        background-color: white;
        display: block;
        padding: 0;
        @include mq($min-width: map-get($breakpoints, s)) {
            padding: 0 30px;
            font-size: 35px;
            display: inline;
        }
    }
}
h2.red-line-title {
    text-align: center;
    line-height: 55px;
    font-weight: normal;
    position: relative;
    font-size: 25px;
    font-weight: bold;
    > span {
        padding: 0 30px;
        background-color: white;
    }
}

h1.page-title{
    line-height: 50px;
    font-weight: normal;
    position: relative;
    font-size: 32px;
    font-weight: 400;
    > span {
        background-color: white;
        @include mq($min-width: map-get($breakpoints, s)) {
            padding: 0 30px;
            font-size: 35px;
            display: inline;
        }
    }
}

.lead{
    line-height: 30px;
    font-size: 22px;
}

#opaccCMS{
    margin-left: -10px;
    margin-right: -10px;
    h1{
        font-weight: 700;
        font-size: 35px;
        line-height: 50px
    }
    h2{
        font-weight: 700;
        font-size: 22px;
        line-height: 30px
    }
    h3{
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 6px;
        margin-top: 20px;
        color: #434343
    }
    h4{
        font-weight: 700;
        font-size: 18px
    }
    a:not([data-op-style="loud"]){
        color: $brand-color-1;
        font-size: 16px;
        font-weight: 700;
    }
    .padding-10 {
        padding: 10px;
    }
    .flip-wrapper.flipper{
        position: relative;
        height: 200px;
        width: 300px;
        //margin: 0 auto;
        margin: 0;
        a {
            color: white;
        }
        .front{
            background-color: gray;
            position: relative;
            padding: 0;
            .flip-overview-image{
                img{
                    margin: 0;
                    padding: 0;
                }
            }
        }
        .back {
            background-color: $brand-color-1;
            position: relative;
            font-size: 18px;
            color: white;
            .flip-detail-text {
                position: absolute;
                top: 15px;
                left: 15px;
                h3{
                    font-size: 2rem;
                    font-weight: 700; 
                    color: white;
                    margin: 0 0 1rem;
                }
            }
        }
    }
}

.promo.row {
    .column {
        > div {
            border: 1px solid rgba(41,46,51,0.1);
            box-shadow: 0 0 10px rgba(41,46,51,0.2);
            text-align: center;
            > a {
                margin-top: 2rem;
                margin-bottom: 2rem;
                border-radius: 10px;
            }
            > h3 {
                margin-bottom: 2rem !important;
            }
        }
    }
}

.person{
    // wird per jQuery eingeblendet
    display: none;
    min-height: 200px;
    float: left;
    width: 100%;
    margin: 0 10px 20px;
    @include mq($min-width: map-get($breakpoints, s)) {
        width: 45%;
    }

    @include mq($min-width: 990px) {
        width: 30%;
    }
    @include mq($min-width: map-get($breakpoints, l)) {
        width: 300px;
    }
    // @include mq($min-width: map-get($breakpoints, xl)) {
    //     width: 25%;
    // }
    clear: none;
    .text-left {
        text-align: left;
    }
    &.clearfix{
        // width: initial;
        // clear: none;
    }
    .team-wrapper.flipper{
        position: relative;
        height: 200px;
        width: 300px;
        //margin: 0 auto;
        margin: 0;
        a {
            color: white!important;
        }
        .front{
            background-color: gray;
            position: relative;
            padding: 0;
            .person-overview-image{
                img{
                    margin: 0;
                    padding: 0;
                }
            }
            .person-overview-name{
                position: absolute;
                bottom: 0;
                background-color: $brand-color-1;
                padding: 5px 15px;
                min-width: 175px;
                box-sizing: border-box;
                font-family: AvantGardeGothicITCW01Md,Arial,sans-serif;
                font-size: 16px;
                color: white;
            }
        }
        .back {
            background-color: $brand-color-1;
            position: relative;
            font-size: 18px;
            color: white;
            .person-detail-top {
                position: absolute;
                top: 25px;
                left: 25px;
                p.name,
                a.name,
                span.name {
                    font-weight: 700;
                }
            }
            .person-detail-bottom {
                position: absolute;
                bottom: 20px;
                left: 25px;
            }
        }
    }
}

[data-editmode="true"]{
    .ui-draggable{
        border: 2px dashed #ddd;
        margin: 3px 0;
    }
    .person{
        @extend .person;
        float: none;
        width: 100%;
        clear: both;
        display: block;
        width: 100%;
        height: 410px;
        .front,
        .back{
            width: 100%;
            height: 100%;
        }
        .back{
            margin-top: 10px;
        }
    }
}

.team-menu{
    ul{
        margin: 40px 0;
        padding: 0;
        padding-left: .5rem;
        text-align: left;
        li{
            display: inline-block;
            margin-right: 25px;
            a{
                font-size: 1.6rem!important;
                color: #5f5e5e!important;
                text-decoration: none;
                &.selected{
                    color: $brand-color-1!important;
                }
            }
        }
    }
}

// Accordion / Tabs

.custom-accordion{
    @extend .opc-tabs;
    .tab-content-wrapper{
        margin-bottom: 4px;
    }
    .tab-content-inner {
        border: none;
    }
}

[data-editmode="true"] .tab-body{
    max-height: initial!important;
    opacity: 1!important;
}

.title-pic {
    height:auto;
    @include mq($min-width: map-get($breakpoints, 'medium')) {
        height: 450px;
    }
    top: 122px;
    left: 0;
    right: 0;
    border-bottom: 1px solid rgba(0,0,0,.1);
    margin: 0;
    padding: 0!important;
}
.title-pic .section-inner{
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
}
.title-pic .section-inner img{
    margin: 0;
    width: 100%;
}

.carousel-item {
    height:250px;
    @include mq($min-width: map-get($breakpoints, 'medium')) {
        height:920px;
    }  
    .carousel-item-image {
        background-position: center center;
        background-size: cover;
        width: 100%;
        height: 100%;
    }
}

.category-item-image-wrapper {
    height:180px;
    @include mq($min-width: map-get($breakpoints, 'medium')) {
        height:540px;
    }  
    .category-item-image {
        background-position: center center;
        background-size: cover;
        width: 100%;
        height: 100%;
    }
}

.text-center{
    text-align: center;
}
img.margin20{
    margin-bottom: 20px!important;
}
.no-margin{
    margin: 0!important;
}
.space20{
    height: 40px!important;
}
.gray-section{
    background-color: #5f5e5e;
    padding-top: 80px;
    padding-bottom: 80px;
    width: 100%;
    margin-left: calc((100% - 100vw) / 2);
    margin-right: calc((100% - 100vw) / 2);
    padding-left: calc((100vw - 100%) / 2);
    padding-right: calc((100vw - 100%) / 2);
    box-sizing: content-box;
    color: #fff;
    h1.red-line-title > span {
        background-color: #5f5e5e;
    }
    &-head{
        padding-bottom: 0;
    }
    &-bottom{
        padding-top: 0;
    }
    &.space20{
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.testimonial-wrapper{
    float: left;
    width: 100%;
    @include mq($min-width: map-get($breakpoints, m)) {
        width: calc(100% / 1.5);
    }
}
.testimonial{
    &.clearfix{
        clear: none;
    }
    .ui-draggable &.clearfix{
        clear: both;
        width: 100%;;
        float: none;
        width: 100%;
    }
    display: flex!important;
    flex-wrap: wrap;
    @include mq($min-width: map-get($breakpoints, xs)) {
        flex-wrap: nowrap;
    }
    .portrait{
        padding-right: 8rem;
        padding-left: 8rem;
        flex-basis: 100%;
        @include mq($min-width: map-get($breakpoints, xs)) {
            flex-basis: 20%;
            padding-right: 1rem;
            padding-left: 0;
        }
        img{
            max-width: 100%!important;
            margin-top: 0;
            margin-left: auto;
            margin-right: auto;
            padding-left: 0;
            padding-right: 0;
        }
    }
    .statement{
        flex-basis: 100%;
        text-align: center;
        @include mq($min-width: map-get($breakpoints, xs)) {
            flex-basis: 80%;
            text-align: left;
        }
        padding-left: 1rem;
        color: #434343;
        h3.red{
            color: $brand-color-1;
            font-size: 2rem;
            margin-bottom: 1.5rem;
        }
        blockquote{
            &::before{
                content: "\00ab";
                margin-right: .5rem;
            }
            &::after{
                content: "\00bb";
                margin-left: .5rem;
            }
            font-size: 2rem;
            padding: 0;
            margin: 0;
            border-left: none;
            font-size: 1.8rem;
            line-height: 28px;
            font-style: italic;
        }
        .testimonial-name{
            margin: 1.5rem 0 0;
            font-size: 1.6rem;
            font-style: italic;
            font-weight: 700;
        }
        .testimonial-function{
            font-size: 1.6rem;
            font-style: italic;
        }
    }
}

.kontakt{
    margin-top: 4rem;
    margin-bottom: 4rem;
    &.clearfix{
        clear: none;
    }
    @include mq($min-width: map-get($breakpoints, m)) {
        margin-top: 0;
        width: calc(100% / 3);
    }
    float: right;
    padding-left: 2rem;
    .ui-draggable &.clearfix{
        clear: both;
        width: 100%;
        float: none;
        width: 100%;
    }
    h3.red{
        color: $brand-color-1;
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }
    p{
        font-size: 1.6rem;
        margin: 1rem 0;
    }
    &::after{
        content: "";
        display: table;
        clear: both;
        margin-bottom: 4rem;
    }
}
    
.showroom-contact{
    display: flex;
    justify-content: space-between;
    >div{
        flex-basis: 50%;
        margin-right: 2rem;
        &:last-of-type {
            margin-right: 0;
        }
        .image{

        }
        .info{
            .name{

            }
            .text{

            }
        }
    }
}

.ratgeber {
    display: flex;
    flex-wrap: wrap;
    .padding-10 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
    }
    .cta-wrapper {
        margin-top: 5rem;
        a {
            margin-top: 1rem;
            width: 100%;
        }
    }
    .column.sixth {
        width: 50%;
        @include mq($min-width: map-get($breakpoints, s)) {
            width: 33%;
        }
        @include mq($min-width: map-get($breakpoints, l)) {
            width: 16.6%;
        }
        .contentwrapper {
            padding: .5rem;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            img {
                height: 6rem;
                width: auto;
                max-width: none;
            }
        }
    }
}

.showroom-category{
    .opc-asset{
        max-height: 100%;
    }
}

.contactform,
.openinghours{
    &.clearfix{
        clear: none;
        width: 100%;
    }
    .ui-draggable &.clearfix{
        clear: both;
        width: 100%;;
        float: none;
        width: 100%;
    }
}
.contactform{
    width: calc(calc(100% / 1.5) - 1rem);
    margin-right: 1rem;
    float: left;
}
.openinghours{
    width: calc(calc(100% / 3) - 1rem);
    margin-left: 1rem;
    float: left;
}

.scroll-indicator {
    position: absolute;
    left: 50%;
    z-index:2;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 45px;
    width: 50px;
    height: 50px;
    margin-left: 4px;
    text-align: center;
    opacity: .9;
    .scroll-arrows {
        -webkit-transition: all 300ms cubic-bezier(0.77,0,0.175,1);
        -moz-transition: all 300ms cubic-bezier(0.77,0,0.175,1);
        -ms-transition: all 300ms cubic-bezier(0.77,0,0.175,1);
        -o-transition: all 300ms cubic-bezier(0.77,0,0.175,1);
        transition: all 300ms cubic-bezier(0.77,0,0.175,1);
        display: block;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        border-right: 3px solid #fff;
        border-bottom: 3px solid #fff;
        margin: 0 auto;
        width: 20px;
        height: 20px;
        -webkit-animation: arrow-scroll 1s infinite;
        -moz-animation: arrow-scroll 1s infinite;
        animation: arrow-scroll 1s infinite;
        -webkit-animation-direction: alternate;
        -moz-animation-direction: alternate;
        animation-direction: alternate;
    }
    .scroll-arrows.one {
        -webkit-animation-delay: .1s;
        -moz-animation-delay: .1s;
        animation-delay: .1s;
        margin-top: 1px;
    }
    .scroll-arrows.two {
        -webkit-animation-delay: .2s;
        -moz-animation-delay: .2s;
        animation-delay: .2s;
        margin-top: -8px;
    }
    .scroll-arrows.three {
        -webkit-animation-delay: .3s;
        -moz-animation-delay: .3s;
        animation-delay: .3s;
        margin-top: -8px;
    }
    &:hover {
        opacity: 1;
    }
}

@-webkit-keyframes arrow-scroll {
    0% {
        opacity: 0
    }

    50% {
        opacity: .5
    }

    100% {
        opacity: 1
    }
}

@-moz-keyframes arrow-scroll {
    0% {
        opacity: 0
    }

    50% {
        opacity: .5
    }

    100% {
        opacity: 1
    }
}

@keyframes arrow-scroll {
    0% {
        opacity: 0
    }

    50% {
        opacity: .5
    }

    100% {
        opacity: 1
    }
}



// Fix
.page-cms{
    padding: 0 1rem;
    .row{
        img{
            max-width: 100%;
        }
    }
    .opc-category-navigation ul .category-item .category-item-description {
        display: block;
    }
    [data-cms-menu] {
        &.hideme {
            display: none;
            @include mq($min-width: map-get($breakpoints, m)) {
                display: block;
            }
        }
        .portlet-divider {
            @include mq($min-width: map-get($breakpoints, m)) {
                display: none;
            }
        }
        .opc-navigation[data-op-navtype="simple"] {
            .navigation-level-1 {
                >li {
                    // margin-bottom: 2rem;
                    >a {
                        &::before {
                            display: none;
                        }
                    }
                    &.is-active {
                        >a {
                            font-weight: bold;
                        }
                    }
                }
            }
            .navigation-level-2 {
                >li {
                    >a {
                        padding-left: 1rem;
                    }
                    &:hover {
                        >a {
                            background: #f8f8f8;
                        }
                    }
                    &.is-active {
                        >a {
                            font-weight: bold;
                            background: #eee;
                        }
                    }
                }
            }
        }
        .opc-button.back{
            font-weight: 700;
            padding-left: 0;
            &::before{
                content: '';
                width: 1em;
                height: 1em;
                display: inline-block;
                background-image: inline-svg($op-icon-angle-left-svg, #000);
                background-size: 80%;
                background-repeat: no-repeat;
                background-position: center;
                vertical-align: middle;
            }
        }
    }
    .menu-toggle {
        background: rgba(238, 238, 238, 0.8);
        padding: 1rem 2rem;
        text-align: center;
        margin-bottom: 2rem;
        cursor: pointer;
        display: block;
        @include mq($min-width: map-get($breakpoints, m)) {
            display: none;
        }
    }
    .opc-cms-content-wrapper {
        .column.third {
            width: 100%;
        }
    }
}
.viewport{
    .page-start .opc-category-navigation ul li {
        flex-basis: 100%;
        width: 100%;
        max-width: 100%;
        @include mq($min-width: map-get($breakpoints, xs)) {
            flex-basis: calc(100% / 2);
            width: calc(100% / 2);
            max-width: calc(100% / 2);
        }
        @include mq($min-width: map-get($breakpoints, m)) {
            flex-basis: calc(100% / 3);
            width: calc(100% / 3);
            max-width: calc(100% / 3);
        }
    }
}

.opc-item-list.dmas-list {
    .item-list-body {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        @media only screen and (min-width: 768px) {
             .list-item {
                flex-wrap: nowrap;
                flex-basis: 50%;
                padding: 5px;
                border-bottom: none;
                padding-right: 14px;
                &::before {
                    content:'';
                    width: 2em;
                    height: 2em;
                    background-image: inline-svg($op-icon-angle-right-svg, #000);
                    background-repeat: no-repeat;
                    background-size: 50%;
                    background-position-y: center;
                }
            }
        }
    }
}

.showrooms,
.segmente-category{
    .opc-category-navigation{
        ul{
            li{
                flex-basis: 100%;
                width: 100%;
                max-width: 100%;
                @include mq($min-width: map-get($breakpoints, s)) {
                    flex-basis: calc(100% / 2);
                    width: calc(100% / 2);
                    max-width: calc(100% / 2);
                }
                @include mq($min-width: map-get($breakpoints, m)) {
                    flex-basis: calc(100% / 3);
                    width: calc(100% / 3);
                    max-width: calc(100% / 3);
                }
                .category-item{
                    .category-item-description{
                        display: block;
                    }
                }
            }
        }
    }
}

.center{
    text-align: center!important;
}