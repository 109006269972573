/*###############################################

@Title: Project Theme Config
@Description:
This file contains the config settings for the theme. It will overwrite the core vars.

###############################################*/

/*
 
 Basics
 ---------------------------------------------------- */

// Max Width and Max Width Paddings
//$mw: 940px;
$mw: 1140px;
$mw-padding: 2rem;

// Grid
$_opc-grid-columns: 12;
$_opc-grid-gutter-width: 10px;
$_opc-grid-column-width: 120px;
 
/*
 
 Colors
 ---------------------------------------------------- */

$transparent: transparent;

$cab-footermenu-color: #5f5e5e;
$gray: #5f5e5e;
$black: #000;
$white: #fff;
$lightgray: #ebebeb;

$brand-color-1: #e41b13;
//$brand-color-2: #3d4961;
$brand-color-2: $gray;
$brand-color-3: #776b5f;

$success-color: #70c391;
$error-color : #fa7979;
$info-color: #b0ddfa;
$warning-color: #fff8bb;

// button colors
$primary-button-color : $brand-color-2;
$secondary-button-color : #ccc;
$loud-button-color : $brand-color-1;

// state colors
$success-color : #70c391;
$error-color : #fa7979;
$info-color : #78b3da;
$warning-color : #fff8bb;
$progress-color : #ccc;

// stock colors
$stock-on-stock : $success-color;
$stock-out-stock : $error-color;
$stock-call-stock : $info-color;
$stock-less-stock : #f0bb54;
$stock-na-stock : $error-color;

 
// breakpoints
$breakpoints: ( 
    'tiny': 480px,
    'small': 640px,
    'medium': 800px,
    'normal': 1020px,
    'large': 1400px,
    // 'xs': 480px,
    // 's': 640px,
    // 'm': 800px,
    // 'l': 1024px,
    // 'xl': 1400px
    'xs': 480px,
    's': 640px,
    'm': 768px,
    //'l': 940px,
    'l': 1140px,
    'xl': 1280px
);


// sizes 
$padding: ( 
    'tiny': 0.5rem, 
    'small': 0.75rem, 
    'medium': 1.25rem, 
    'normal': 1.25rem, 
    'large': 1.75rem, 
    'huge': 2rem

    );
/* !!!! DEPRECATED, JUST FOR COMPATIBLITY. USE PADDING INSTEAD !!!!! */
$SIZES: ( 'tiny': 0.5rem, 'small': 0.75rem, 'medium': 1.25rem, 'normal': 1.25rem, 'large': 1.75rem, 'huge': 2rem);

// heights
$heights: ( 
    'tiny': 2.6rem, 
    'small': 3.2rem, 
    'medium': 4rem, 
    'normal': 4rem, 
    'large': 5rem, 
    'huge': 6rem
);

$spacing: ( 
    'small': 2rem, 
    'medium': 3rem, 
    'large': 4rem
);


/*
 
 typography
 ---------------------------------------------------- */

// font-size
$_opc-base-font-size : 1.6rem;
// fluid typography 
$_opc-fluid-typo : "false";
$_opc-font-size-multiplyer : 1.2;
$_opc-font-min-width : 320px;
$_opc-font-max-width : $mw;
// font family and styles
$_opc-base-font-weight : 400;
$_opc-base-line-height : 1.4;
$_opc-headline-font-weight : 400;
$_opc-headline-line-height : 1.25;
$_opc-lead-font-weight : 400;
$_opc-lead-line-height : 1.25;
// headlines
$_opc-font-size-h1 : 28px;
$_opc-font-size-h2 : 24px;
$_opc-font-size-h3 : 20px;
$_opc-font-size-h4 : 18px;
$_opc-font-size-h5 : 16px;
$_opc-font-size-h6 : 14px;
$_opc-font-size-h1-max : $_opc-font-size-h1 * $_opc-font-size-multiplyer;
$_opc-font-size-h2-max : $_opc-font-size-h2 * $_opc-font-size-multiplyer;
$_opc-font-size-h3-max : $_opc-font-size-h3 * $_opc-font-size-multiplyer;
$_opc-font-size-h4-max : $_opc-font-size-h4 * $_opc-font-size-multiplyer;
$_opc-font-size-h5-max : $_opc-font-size-h5 * $_opc-font-size-multiplyer;
$_opc-font-size-h6-max : $_opc-font-size-h6 * $_opc-font-size-multiplyer;
// lead and copy text
$_opc-font-size-lead : $_opc-base-font-size * 1.4;
$_opc-font-size-lead-max : $_opc-font-size-lead * $_opc-font-size-multiplyer;
$_opc-font-size-small : 1.2rem;
$_opc-font-size-small-max : $_opc-font-size-small * $_opc-font-size-multiplyer;
$_opc-font-size-copy : $_opc-base-font-size;
$_opc-font-size-copy-max : $_opc-font-size-copy * $_opc-font-size-multiplyer;
$_opc-font-size-s : 1.2rem;
$_opc-font-size-m : 1.4rem;
$_opc-font-size-l : 1.6rem;
$_opc-font-size-xl : 1.8rem;
/*
 
 SVG Icons
 ---------------------------------------------------- */

// make svg icons available in sass as background image
$op-icon-cross-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path style=" " d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z "/></svg>';
$op-icon-check-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path style=" " d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z "/></svg>';
$op-icon-angle-right-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><polygon points="11.5,28.5 10.5,27.5 22,16 10.5,4.5 11.5,3.5 24,16 "/></svg>';
$op-icon-angle-left-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path d="M19.031 4.281l-11 11-0.688 0.719 0.688 0.719 11 11 1.438-1.438-10.281-10.281 10.281-10.281z"></path></svg>';
$op-icon-angle-down-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path style=" " d="M 4.21875 10.78125 L 2.78125 12.21875 L 15.28125 24.71875 L 16 25.40625 L 16.71875 24.71875 L 29.21875 12.21875 L 27.78125 10.78125 L 16 22.5625 Z "/></svg>';
$op-icon-stock-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><circle cx="50" cy="50" r="40" /></svg>';
$op-icon-mailshare-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" role="img" aria-labelledby="at-svg-email-1" class="at-icon at-icon-email" style="fill: rgb(255, 255, 255); width: 32px; height: 32px;"><title id="at-svg-email-1">Email</title><g><g fill-rule="evenodd"></g><path d="M27 22.757c0 1.24-.988 2.243-2.19 2.243H7.19C5.98 25 5 23.994 5 22.757V13.67c0-.556.39-.773.855-.496l8.78 5.238c.782.467 1.95.467 2.73 0l8.78-5.238c.472-.28.855-.063.855.495v9.087z"></path><path d="M27 9.243C27 8.006 26.02 7 24.81 7H7.19C5.988 7 5 8.004 5 9.243v.465c0 .554.385 1.232.857 1.514l9.61 5.733c.267.16.8.16 1.067 0l9.61-5.733c.473-.283.856-.96.856-1.514v-.465z"></path></g></svg>';
$op-icon-whatsappshare-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" role="img" aria-labelledby="at-svg-whatsapp-2" class="at-icon at-icon-whatsapp" style="fill: rgb(255, 255, 255); width: 32px; height: 32px;"><title id="at-svg-whatsapp-2">WhatsApp</title><g><path d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z" fill-rule="evenodd"></path></g></svg>';
$op-icon-custshop-offer-svg: '<svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 -960 960 960" width="32" style="fill: rgb(255, 255, 255); width: 32px; height: 32px;"><path d="M450-234h60v-129h130v-60H510v-130h-60v130H320v60h130v129ZM220-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h361l219 219v521q0 24-18 42t-42 18H220Zm331-554v-186H220v680h520v-494H551ZM220-820v186-186 680-680Z"/></svg>';
$op-icon-custshop-muster-svg: '<svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 -960 960 960" width="32" style="fill: rgb(255, 255, 255); width: 32px; height: 32px;"><path d="M161-120q-18-8-26-17.5T120-165l678-675q15 5 26.5 16.5T840-796L161-120Zm-41-278v-86l356-356h86L120-398Zm0-320v-62q0-24 18-42t42-18h62L120-718Zm720 156v86l-23 23q-17-7-34.5-10.5T747-469l93-93ZM398-120l102-102q2 18 6 35t11 34l-33 33h-86Zm302-90H580v-60h120v-120h60v120h120v60H760v120h-60v-120Z"/></svg>';
/*
 
 Buttons
 ---------------------------------------------------- */

$_opc-button-base-progress-scale : 0.8;
$_opc-button-base-border-radius : 0;
$_opc-button-primary-link-color : $primary-button-color;
$_opc-button-primary-link-color-hover : darken($_opc-button-primary-link-color, 20%);
$_opc-button-secondary-link-color : $secondary-button-color;
$_opc-button-secondary-link-color-hover : darken($_opc-button-secondary-link-color, 20%);
$_opc-button-primary-border-radius : $_opc-button-base-border-radius;
$_opc-button-primary-color : rgba(#fff, 0.8);
$_opc-button-primary-color-hover : rgba(#fff, 1);
$_opc-button-primary-bg : $primary-button-color;
$_opc-button-primary-bg-hover : lighten($_opc-button-primary-bg, 10%);
$_opc-button-primary-progress-color : $progress-color;
$_opc-button-primary-success-color : $success-color;
$_opc-button-secondary-border-radius : $_opc-button-base-border-radius;
$_opc-button-secondary-color : rgba(#000, 0.8);
$_opc-button-secondary-color-hover : rgba(#000, 1);
$_opc-button-secondary-bg : $secondary-button-color;
$_opc-button-secondary-bg-hover : lighten($secondary-button-color, 10%);
$_opc-button-secondary-progress-color : $progress-color;
$_opc-button-secondary-success-color : $success-color;
$_opc-button-loud-border-radius : $_opc-button-base-border-radius;
$_opc-button-loud-color : rgba(#fff, 0.8);
$_opc-button-loud-color-hover : rgba(#fff, 1);
$_opc-button-loud-bg : $loud-button-color;
$_opc-button-loud-bg-hover : lighten($loud-button-color, 10%);
$_opc-button-loud-progress-color : $progress-color;
$_opc-button-loud-success-color : $success-color;
$_opc-button-font-size-s : $_opc-font-size-s;
$_opc-button-font-size-m : $_opc-font-size-m;
$_opc-button-font-size-l : $_opc-font-size-l;
$_opc-button-font-size-xl : $_opc-font-size-xl;

/*
 
 Forms
 ---------------------------------------------------- */

$_opc-form-field-font-family : sans-serif;
$_opc-form-field-font-size : inherit;
$_opc-form-field-margin : 2rem;
$_opc-form-field-border : 1px solid #ccc;
$_opc-form-field-border-hover : 1px solid #bbb;
$_opc-form-field-border-focus : 1px solid #aaa;
$_opc-form-field-border-invalid : 1px solid $error-color;
$_opc-form-field-border-valid : 1px solid $success-color;
$_opc-form-field-color : #000;
$_opc-form-field-color-invalid : $error-color;
$_opc-form-field-color-message-invalid: $error-color;
$_opc-form-field-color-valid : $success-color;
$_opc-form-field-color-message-valid : $success-color;
$_opc-form-field-placeholder-color : #ccc;
$_opc-form-field-bg : #fff;
$_opc-form-field-bg-hover : #fff; 
$_opc-form-field-bg-focus : #fff;
$_opc-form-field-label-color : #000;
$_opc-form-field-label-font-weight : 600;
$_opc-form-field-label-font-family : inherit;
$_opc-form-field-msg-font-size : 1.2rem;
$_opc-form-field-font-size-s : $_opc-font-size-s;
$_opc-form-field-font-size-m : $_opc-font-size-m;
$_opc-form-field-font-size-l : $_opc-font-size-l;
$_opc-form-field-font-size-xl : $_opc-font-size-xl;

$_opc-radio-width : 2rem;
$_opc-radio-height : 2rem;
$_opc-radio-border : 1px solid #ccc;
$_opc-radio-border-hover : 1px solid #bbb;
$_opc-radio-border-focus : 1px solid #aaa;
$_opc-radio-border-checked : 1px solid #ccc;
$_opc-radio-border-radius : 2rem;
$_opc-radio-color : #000;
$_opc-radio-bg : #fff;
$_opc-radio-bg-hover : #fff;
$_opc-radio-bg-focus : #fff;
$_opc-radio-bg-checked : #fff;
$_opc-radio-icon : inline-svg($op-icon-check-svg, '#333');
$_opc-radio-icon-checked : inline-svg($op-icon-check-svg, '#333');
$_opc-radio-icon-size : 1.6rem;
$_opc-radio-label-font-size : 1.4rem;

$_opc-checkbox-width : 2rem;
$_opc-checkbox-height : 2rem;
$_opc-checkbox-border : 1px solid #ccc;
$_opc-checkbox-border-hover : 1px solid #bbb;
$_opc-checkbox-border-focus : 1px solid #aaa;
$_opc-checkbox-border-checked : 1px solid #ccc;
$_opc-checkbox-border-radius : 0.5rem;
$_opc-checkbox-color : #000;
$_opc-checkbox-bg : #fff;
$_opc-checkbox-bg-hover : #fff;
$_opc-checkbox-bg-focus : #fff;
$_opc-checkbox-bg-checked : #fff;
$_opc-checkbox-icon : inline-svg($op-icon-check-svg, '#333');
$_opc-checkbox-icon-checked : inline-svg($op-icon-check-svg, '#333');
$_opc-checkbox-icon-size : 1.6rem;
$_opc-checkbox-label-font-size : 1.4rem;

$_opc-select-icon: inline-svg($op-icon-angle-down-svg, '#999');

/*
 
 Links
 ---------------------------------------------------- */

$_opc-link-color: $brand-color-1;
$_opc-link-color-visited: $_opc-link-color;
$_opc-link-color-hover: lighten($_opc-link-color, 30%);
$_opc-link-color-active: lighten($_opc-link-color, 10%);
$_opc-link-underline-animation-duration: 0.2s;


/*
 
 Navigation
 ---------------------------------------------------- */
$_opc-navigation-color-active: $gray;