.opc-favorite-modal {

    .opc-mini-label {
        margin-bottom: 1rem;
    }
    
    .favorite-list {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-bottom: 4rem;

        .favorite-list-item {
            display: flex;
            align-items: center;
            position: relative;
            padding: 1rem 0;
            border-bottom: 1px solid #eee;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &[data-op-artexist="true"], &.is-progress {
                pointer-events: none;
                opacity: 0.2;
                cursor: default;
            }

            &:hover {
                background: #eee;
                padding: 1rem 0.5rem;
            }
            .favorite-list-item-title {
                font-weight: bold;
                
            }
            .favorite-list-item-description {

            }
            .favorite-list-item-count {
                position: absolute;
                right: 0.5rem;
                top: 50%;
                transform: translateY(-50%);
                display: flex;
                justify-content: center;
                align-items: center;
                width: 2.4rem;
                height: 2.4rem;
                border-radius: 50%;
                background: $brand-color-2;
                color: #fff;
                font-size: 1.2rem;

            }

        }
    }

    .favorite-modal-footer {
        .favorite-modal-footer-input {
            display: flex;
            flex-wrap: wrap;

            .opc-input {
                width: 100%;
                margin-bottom: 0.5rem; 
            }
            .opc-button {
                width: 100%;
            }
        }
        
        @include mq($min-width: 500px) {
            .favorite-modal-footer-input {
                flex-wrap: nowrap;
                .opc-input {
                    width: 100%;
                    flex-grow: 1;
                    margin-bottom: 0; 
                    margin-right: 1rem;
                }
                .opc-button {
                    width: auto;
                    flex-shrink: 0;
                }
            }   
        }
    }

} 