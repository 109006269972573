/*###############################################

@Title: Segment navigation (paging)
@Description:
This file contains the segment navigation

###############################################*/

.opc-segment-navigation {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin: 2rem 0;
    ul {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
        align-items: center;
        width: 100%;
        justify-content: center;
    }
    a {
        display: block;
        width: 4rem;
        height: 4rem;
        margin: 0 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid transparent;
        font-size: 2rem;
        font-family: sans-serif;
        &:hover {
            border: 1px solid #ccc;
        }

        &.is-active {
            border: 1px solid #ccc;
            background: #ccc;
            color: #000;
        } 
    }
    .disabled,
    .is-disabled {
        opacity: 0.2;
        cursor: default;
        pointer-events: none;
    }
}