/*###############################################

@Title: Language Selection
@Description:
This file contains the language selection styles

###############################################*/

$_opc-language-selection-list-bg: #333 !default;
$_opc-language-selection-list-color: rgba(#fff, 0.8) !default;
.opc-language-selection {
    position: relative;
    &[data-op-layout="list"] {
        .language-selection-label {
            cursor: pointer;
            span {
                font-weight: bold;
            }
            &::after {
                margin-left: 0.5rem;
                content: '';
            }
            &:focus {
                outline: none;
            }
        }
        ul {
            display: none;
            background: $_opc-language-selection-list-bg;
            color: $_opc-language-selection-list-color;
            z-index: 90000;
            position: absolute;
            top: 100%;
            right: 0;
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                border-bottom: 1px solid rgba(#fff, 0.1);
                a {
                    display: block;
                    padding: 0.5rem 1rem;
                    color: inherit;
                    &:hover {
                        background: rgba(#000, 0.2);
                    }
                }
                > span {
                    display: block;
                    padding: 0.5rem 1rem;
                    color: inherit;
                    cursor: pointer;
                    &:hover {
                        background: rgba(#000, 0.2);
                        color: lighten(#000, 40%);
                    }
                }
            }
        }
        &.is-open {
            ul {
                display: block;
            }
        }
    }

	&[data-op-layout="button"] {
		a {
			vertical-align: unset;
			&.is-active {
				font-weight: bold;
			}
		}
		> span {
			vertical-align: unset;
            cursor: pointer;

            &:hover {
                color: lighten(#000, 40%);
            }

			&.is-active {
				font-weight: bold;
			}
		}
	}
}