/*###############################################

@Title: Page Checkout
@Description:


###############################################*/

.page-checkout {
    .content-box {
        .content-box-head {
            padding: 2rem;
            position: relative; // title
            h2 {
                margin: 0;
                padding: 0;
                margin-bottom: 1rem;
                span {
                    font-size: 3rem;
                    font-size: inherit;
                    width: 3rem;
                    margin-right: 0;
                    display: inline-block;
                }
            } // toggler
            .content-box-signalisation {
                position: absolute;
                right: 2rem;
                top: 2rem; //width: 2rem;
                //height: 2rem;
                text-align: center;
                font-size: 2rem;
                line-height: 2rem;
                display: flex;
                align-items: center;
                svg {
                    margin-left: 2rem;
                    color: $success-color;
                }
                .opc-button {
                    opacity: 1;
                    visibility: visible;
                }
                svg {
                    opacity: 1;
                    transition: all 0.4s ease-in-out;
                }
            }
            .content-box-head-subline {
                padding: 0 0 0 3rem;
                opacity: 1; //transition: all 0.2s ease-in-out;
                span {
                    display: inline-block;
                    margin-right: 2rem;
                    margin-bottom: 0.5rem;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
        &.is-open {
            .content-box-head {
                padding-bottom: 0;
                @include mq($min-width: map-get($breakpoints, m)) {}
                .content-box-toggler {
                    transform: translateY(-50%) rotate(-180deg);
                }
                .content-box-head-subline {
                    opacity: 0;
                    visibility: hidden;
                    height: 0;
                }
                .content-box-signalisation {
                    .opc-button {
                        opacity: 0;
                        visibility: hidden;
                    }
                    svg {
                        opacity: 0;
                    }
                }
            }
        }
        &.is-open {
            .content-box-body {
                display: block;
            }
        }
        .content-box-body {
            display: none;
        }
        &[data-op-isdone="true"] {
            .content-box-signalisation {
                visibility: visible;
                opacity: 1;
            }
        }
        &[data-op-isdone="false"] {
            .content-box-signalisation {
                .opc-button {
                    visibility: hidden;
                    opacity: 0;
                }
                .op-icon-check {
                    color: #ddd;
                }
            }
            &.is-open {
                .content-box-signalisation {
                    visibility: hidden;
                    opacity: 0;
                }
            }
        }
        &[data-op-type="summary"] {
            .opc-button {
                &[data-op-isok="false"] {
                    pointer-events: none;
                    opacity: 0.25;
                }
            }
        }
        #la-change-form{
            .opc-form-field {
                display: none;
                flex-grow: 1;
            }
        }
    }
    .checkout-addresses {
        @extend %opc-row;
        display: flex;
        flex-wrap: wrap;
        .opc-form-field-group{
            .list-item-label{
                font-weight: 700;
                padding: 1rem 0 .5rem;
            }
            .list-item-value{
                padding: .5rem 0 1rem;
                border-bottom: 1px solid #ddd;
            }
            display: flex;
            flex-direction: column;
        }
        .shipping-address {
            padding: $_opc-grid-gutter-width;
            width: 100%;
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 50%;
            }
        }
        .billing-address {
            padding: $_opc-grid-gutter-width;
            width: 100%;
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 50%;
            }
        }
        #address-form {
            display: none;
            padding: 1rem;
            border: 1px solid #eee;
            .opc-col {
                margin-bottom: 0;
            }
        }
        .editmode {
            display:none;
        }
        .op-icon-check {
            display:none;
        }
        &.is-changing  {
            .editmode {
                display: flex;
            }
            .opc-form-field {
                display: block!important;
            }
            .list-item-value {
                display: none;
            }
            .op-icon-edit {
                display:none;
            }
            .op-icon-check {
                display:block;
            }
        }
        .opc-button {
            margin-right: 0;
            border: none;
            flex-shrink: 0;
            span {
                display: none;
            }
        }    
    }
    .delivery-selection {
        .opc-radio {
            b {}
            span {
                display: inline-block;
                display: block;
            }
            a {
                display: block;
                margin-top: 0.5rem;
                color: blue;
                text-decoration: underline;
            }
            .shipping-price {
                //position: absolute;
                //right: 2rem;
                //top: 1.5rem;
                display: inline-block;
                margin-left: 1rem;
                color: orange;
                font-weight: bold; //float: right;
                position: absolute;
                right: 1rem;
                top: 1rem;
                span {
                    display: inline-block;
                    margin-top: 0.5rem;
                }
            }
        }
    }
    .payment-selection {
        .payment-icon {}
        .payment-text {
            font-weight: bold;
        }
        .payment-info {
            display: block;
        }
    }
    .button-text-edit{
        display: inline!important;
    }
    .is-changing{
        .button-text-edit{
            display: none!important;
        }
        .button-text-save{
            display: inline!important;
        }
    }
}

.content-box-body-actions.checkout-footer{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .opc-form-field{
        display: block!important;
        flex-grow: 1;
        max-width: 50rem;
        margin-right: auto;
        .form-field-element{
            textarea{
                height: 6rem;;
            }
        }
    }
    .checkout-buttons{
        margin-left: auto;
        flex-grow: 1;
    }
    a{
        flex-grow: 0;
        margin-left: 1rem;
    }
}
.deldate{
    margin-left: -2rem;
    margin-right: -2rem;
    .opc-col{
        margin: 0;
    }
    #check-deldate{
        background: transparent;
        label{
            padding: 0;
            &::before,
            &::after{
                display: none;
            }
        }
    }
}


.basket-summary-item-spec{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > div{
        &::after{
            content: ",";
            margin-right: .5rem;
        }
        &:last-child{
            &::after{
                content: "";
            }
        }
    }
    > div.color{
        >ul{
            // display: flex;
            // flex-wrap: wrap;
            // justify-content: flex-start;
            margin: 0;
            padding: 0;
            float: left;
            >li{
                list-style-type: none;
                flex-basis: 1px;
                padding: 0 .6rem 0 0;
                float: left;
                &::after{
                    content: ',';
                }
                // &:last-child{
                //     &::after{
                //         display: none;
                //     }
                // }
            }
        }
        &::after{
            display: none;
        }
    }
}

.total-amount-brutto{
    &::before{
        content: "(";
    }
    &::after{
        content: ")";
    }
    display: block;
    font-size: 1.2rem;
}

.overlay-spinner{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000,.8);
    z-index: 999;
    &::before {
        content: '';
        width: 3rem;
        height: 3rem;
        background: transparent;
        display: block;
        border-radius: 50%;
        margin: 0 auto;
        position: absolute;
        left: 50%;
        top: calc(50% - 1.5rem);
        margin-left: -1.5rem;
        border: 2px solid transparent;
        border-top: 2px solid $brand-color-1;
        animation-name: rotater;
        animation-duration: 2s;
        animation-iteration-count: infinite;
    }
    &.show{
        display: block;
    }
    @keyframes rotater {
        50% {
            transform: rotate(400deg);
        }
    }
}

.noproceedinfo {
    display:none;
}

.stepInfo{
    color: $brand-color-1;
    margin-bottom: .5rem;
}