/*###############################################

@Title: Topbar
@Description:


###############################################*/


// namespace vars

$_topbar-bg: #fff;
$_topbar-padding: 1rem;
$_topbar-group-divider-color: #eee;


#topbar-login-form {
	display: none;
}

// topbar layout styles

.topbar-wrapper {
	@extend %mw;

	background: $_topbar-bg;
	padding-top: $_topbar-padding;
	padding: 0;

	display: none;

	@include mq($min-width: map-get($breakpoints, l)) {
		display: block;
	}


	.topbar-inner {
		@extend %mw-inner;
		//text-align: right;
		max-width: $mw;
		position: relative;
		font-size: 0;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-top: 14px;
	}

	.topbar-group {
		display: inline-block;
		text-align: left;
		padding-left: 1rem;
		margin-left: 1rem;
		position: relative;
		line-height: 1.6;
		font-size: 1.4rem;

		&:first-child {
			&::before {
				display: none;
			}
		}

		&::before {
			// content: '';
			// display: block;
			// width: 1px;
			// background: $_topbar-group-divider-color;
			// position: absolute;
			// left: 0;
			// top: 0;
			// bottom: 0;
			display: none;
		}

		select {
			font-weight: bold;
			border: none;
		}

		a {
			//color: inherit;
			text-decoration: none;
			color: #000;
			font-size: 14px;
			line-height: 4rem;
			height: 4rem;

			.text {
				font-size: 14px;
				line-height: 1;
			}

			&:hover {
				color: lighten(#000, 40%);
			}
			&.is-active {
				color: $brand-color-1;
			}
		}
		.basket-item-title{
			a{
				line-height: 1.2;
				height: auto;
			}
		}
		.count {
			&::before {
				content: '(';
			}

			&::after {
				content: ')';
			}
		}

		&.search {
			display: inline-block;
			position: static;
			line-height: 1;
			display: block !important;
			padding-left: 1rem;
			padding-right: 0;

			@include mq($min-width: map-get($breakpoints, medium)) {
				//background: $lightbrown;
				z-index: 3;
			}

			.custom-button[data-op-style="link"] {
				width: 2rem;
				height: 2rem;
				padding: 0;
				border: none;
				background: transparent;
				cursor: pointer;
			}

			.op-icon-search {
				font-size: 2rem;
			}
		}
		&.basket{
			.opc-basket-info{
				.opc-button{
					padding: 0;
					margin: 0;
					overflow: visible;
					font-size: 1.4rem;
					.text{
						font-size: 1.4rem;
						margin: 0;
					}
					svg{
						display: none;
					}
					.basket-info-count {
						width: inherit;
						font-size: 1.4rem;;
						border: none;
						font-weight: 700;
						&::before {
							content: '(';
						}
			
						&::after {
							content: ')';
						}
					}
				}
			}
		}
	}

	.admin-panel {}
}

@keyframes searchBoxOpen {
	from {
		transform: scale(0);
	}

	to {
		transform: scale(1.1);
	}

	from {
		transform: scale(1.1);
	}

	to {
		transform: scale(1);
	}
}

@keyframes searchBoxClose {
	from {
		transform: scale(1);
	}

	to {
		transform: scale(1.1);
	}

	from {
		transform: scale(1.1);
	}

	to {
		transform: scale(0);
	}
}

// fix
.f-favorite-infobutton{
	.saldocquantity{
		font-weight: 700;
		&::before{
			content: "(";
		}
		&::after{
			content: ")";
		}
	}
	&.empty {
		border: none;
		.saldocquantity{
			&::before{
				content: "";
			}
			&::after{
				content: "";
			}
		}
	}
}




// Suche
.searchbox {
	&.search {
		.searchbox-wrapper {
			@extend %mw;

			background: $_topbar-bg;
			padding-top: $_topbar-padding;
			padding: 0;

			@include mq($min-width: map-get($breakpoints, m)) {
				display: block;
			}

			.searchbox-inner {
				@extend %mw-inner;
				max-width: $mw;
				position: relative;
				max-height: 0;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				display: inline-block;
				top: 14px;
				left: 0;
				right: 0;
				line-height: 1;
				display: block !important;
				padding-left: 1rem;

				@include mq($min-width: map-get($breakpoints, tiny)) {
					padding-left: 2rem;
				}

				@include mq($min-width: map-get($breakpoints, small)) {
					padding-left: 3.5rem;
				}

				padding-right: 0;

				@include mq($min-width: map-get($breakpoints, medium)) {
					//background: $lightbrown;
					z-index: 1002;
				}

				.custom-button[data-op-style="link"] {
					width: 3rem;
					height: 3rem;
					padding: 0;
					border: none;
					background: transparent;
					cursor: pointer;
				}

				.opc-search {
					display: none;

					&.openSearchBox {
						display: block;
						position: fixed;

						@include mq($min-width: map-get($breakpoints, medium)) {
							position: absolute;
						}

						left: 0;
						right: 0;
						top: 0;
						animation: searchBoxOpen .25s;
						visibility: visible;
						opacity: 1;
						z-index: 1003
					}

					&.closeSearchBox {
						animation: searchBoxClose .5s;
					}
				}

				.op-icon-search {
					font-size: 3rem;
				}
			}
		}
	}
}

// Sprachwahl
.opc-language-selection{
	label{
		display: none;
	}
	.opc-button{
		padding: .3em .5em .3em .1em;
		border-right: 1px solid $gray;
		line-height: normal!important;
		height: auto!important;
		&:last-child{
			border: none;
		}
	}
}