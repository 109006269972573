.opc-carousel {

    .carousel-slide-content {
        padding: 2rem;
        .carousel-slide-content-inner {
            background: rgba(#fff, 0.6);
            max-width: 40rem;
            padding: 2rem;
        }
    }


    h2 {
    
        span {
            @extend .h1;
            //line-height: 1.5;
            //background: rgba(#333, 0.9);
            //box-shadow: 0 0 0 5px rgba(#333, 0.9);
            //color: #fff;
        }
    }

    



}