/*###############################################

@Title: Mainnav
@Description:


###############################################*/

// namespace vars

$mainnav-bg: #494949;
$mainnav-padding: 1rem;


// mainnav layout styles

.mainnav-wrapper {
	@extend %mw;
	position: relative;
	z-index: 100;
	background: $mainnav-bg;
	
    display: none;
    
    @include mq($min-width: map-get($breakpoints, m)) {
		display: block;
	}
	
	@include mq($min-width: map-get($breakpoints, xl)) {
		
    }
    
	.mainnav-inner {
		@extend %mw-inner;
	}
	
	.opc-navigation {
		li a {
			color: #fff;
		}
	}
}

