.opc-basket-summary {
    .basket-summary-item {
        .basket-summary-item-title{
        }
        .basket-summary-item-spec{
            display: flex;
            flex-direction: row;
            > div{
                &::after{
                    content: ",";
                    margin-right: .5rem;
                }
                &:last-child{
                    &::after{
                        content: "";
                    }
                }
            } 
        }
    }
}
.basket-list-item-discount{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    >span{
        padding: .5rem .5rem .5rem 0;
        display: flex;
        align-items: center;
        .text{
            font-size: 1.4rem;
            padding: .3rem;
            background: #434343;
            color: #fff;
            margin: 0;
        }
        .value{
            font-size: 1.4rem;
            padding: .3rem 1rem;
            background: #eee;
        }
    }
}