/*###############################################

@Title: Favorite Button
@Description:
This file contains the favorite button styles

###############################################*/

.opc-favorite-button {
    @extend .opc-button;
    opacity: 0.4;
    &[data-op-type="icon"] { }
    &:hover,
    &:focus {
        opacity: 0.8;
    }
    &.is-active {
        opacity: 1;
    }
    &:focus {
        outline: none;
    }
    svg {
        font-size: 1.4em;
    }
}
