/*###############################################

@Title: Page Details
@Description:


###############################################*/

.page-details {
    .section-wrapper{
        padding-top: $section-padding / 3;
        .opc-button.back{
            font-weight: 700;
            padding-left: 0;
            &::before{
                content: '';
                width: 1em;
                height: 1em;
                display: inline-block;
                background-image: inline-svg($op-icon-angle-left-svg, #000);
                background-size: 80%;
                background-repeat: no-repeat;
                background-position: center;
                vertical-align: middle;
            }
        }
    }
    .article-head {
        //margin-top: 4rem;
        display: flex;
        position: relative;
        flex: 1; //is the same as saying flex: 1 1 auto; --> flex-grow: 1;flex-shrink: 1;flex-basis: auto;

        @include mq($max-width: map-get($breakpoints, 'medium')) {
            flex-wrap: wrap;
        }

        .article-flags {
            order: 0;
            display: block;
            padding: 0 $_opc-grid-gutter-width;
            position: absolute;
            left: 0;
            top: 0;
        }

        .article-image {
            order: 2;
            align-self: flex-start;
            flex-grow: 1;
            padding: $_opc-grid-gutter-width;
            display: flex;
            flex-basis: 1;
            align-items: flex-end;
            justify-content: flex-start;
            flex-direction: column;
            width: 100%;
            max-width: 100%;
            z-index: 2;

            @include mq($min-width: map-get($breakpoints, m)) {
                flex-basis: 0;
            }

            .action-buttons-head {
                position: absolute;
                right: 0;
                top: 0;
                display: flex;
                z-index: 9;
            }

            img.opc-asset {
                max-width: 100%;
                max-height: inherit;
                height: inherit;
            }

            .opc-button.musterbestellung {
                padding: 0;
                font-size: 1.6rem;
                font-weight: 700;

                &::before {
                    content: " ";
                    width: 2rem;
                    height: 2rem;
                    margin-right: .5rem;
                    background-image: url(/webportal/config/layout/themes/project/assets/img/add-circular-button-black.svg);
                    background-size: 2rem;
                }
            }

            @include mq($min-width: map-get($breakpoints, m)) {
                max-height: 100%;

                // width: 40rem; //height: 40rem;
                img {
                    max-width: 100%;
                    // max-height: inherit;
                    // max-height: 40rem;
                    height: inherit;
                }
            }
            .materialo {
                position: relative;
                width: 100%;
                @include mq($min-width: map-get($breakpoints, m)) {
                    min-width: 50rem;
                 }
                
                 .materialo-badge {
                     position: absolute;
                     opacity: 0.9;
                     left:0;
                     color: rgba(255, 255, 255, 0.8);
                     padding:1.1rem;
                     z-index: 9;
                     top:-1rem;
                     &:hover {
                         color: white;
                         background: #ef423b;
                        }
                    }
                    
                    .materialo-badge-tile {
                        color: rgba(255, 255, 255, 0.8);
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: $brand-color-1;
                        border: 1px solid  $brand-color-1;
                        &:hover {
                            color: white;
                            background: #ef423b;
                            }
                        > span {
                            font-size: 20px;
                            font-weight: bold;
                        }
                    }
                .mat-img-wrapper {
                    // max-height: 30rem;
                    display: flex;
                    justify-content: center;
                    overflow: hidden;
                    
                    > img {
                        max-width:none;
                    }
                    @include mq($min-width: map-get($breakpoints, m)) {
                        > img {
                            max-width:none;
                        }
                        max-height: 46rem;
                        min-height: 46rem;
                        max-width: 46rem;
                        display: flex;
                        justify-content: center;
                        overflow: hidden;
                        position: relative;
                        margin-left:auto;
                    }
                }
                .opc-asset {
                    flex-wrap: wrap;
                    justify-content: space-between;
                    max-width: 46rem;
                    margin-left: inherit;
                    @include mq($min-width: map-get($breakpoints, m)) {
                        margin-left: auto;
                    }

                    > li {
                        margin-top: 1rem;
                        max-height: none;
                        min-height: 8rem;
                        max-width: 8rem;
                        height: 8rem;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        overflow: hidden;
                        position: relative;
                        padding:0;
                        &:first-child {
                            margin-left: 0;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                        &.is-selected {
                            border: 2px solid $brand-color-1;
                        }
                        > img {
                            max-width: none;
                            width: auto;
                            
                        }
                    }
                }
            }
            .opc-asset {
                flex-wrap: wrap;
                justify-content: flex-start;
                max-width: 46rem;
                margin-left: auto;
                > li {
                    margin-top: 1rem;
                    max-height: none;
                    min-height: 8rem;
                    max-width: 8rem;
                    height: 8rem;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    overflow: hidden;
                    position: relative;
                    padding:0;
                    &:first-child {
                        margin-left: 0;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    &.is-selected {
                        border: 2px solid $brand-color-1;
                    }
                    > img {
                        max-width: none;
                        width: auto;
                        
                    }
                }
            }
        }

        .article-infos {
            font-size: 2rem;
            order: 1;
            flex-grow: 1;
            flex-basis: 1;
            display: flex;
            flex-direction: column;
            padding: $_opc-grid-gutter-width;
            position: relative;

            @include mq($min-width: map-get($breakpoints, m)) {
                flex-basis: 0;
            }

            h1 {
                padding-right: 3rem;
            }

            .article-art-nr {
                margin-bottom: 1.5rem;
            }

            .article-desc {
                color: #666;
                margin-bottom: 3rem;
                flex-grow: 1; // Lasst den Container so hoch werden, dass die nachfolgenden Elemente am Boden ausgerichtet sind
            }

            .article-spec {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;

                >div {
                    &::after {
                        content: ",";
                        margin-right: .5rem;
                    }

                    &:last-child {
                        &::after {
                            content: "";
                        }
                    }
                }
            }

            .spec-icons {
                .sustainable {
                    max-width: 128px;
                }
                ul.artSpec {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;

                    li.artSpecGroup {
                        label {
                            display: none;
                        }

                        ul {
                            margin: 0;
                            padding: 0;
                            list-style-type: none;
                            float: left;

                            li.artSpecAttr {
                                float: left;
                                height: 6rem;

                                img {
                                    height: 100%;
                                    width: auto;
                                    margin-right: 0.6rem;
                                    margin-bottom: 0.8rem;
                                }
                            }
                        }
                    }
                }

                ul.detail-icons {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;
                    float: left;

                    li {
                        float: left;
                        height: 6rem;

                        img {
                            max-height: 100%;
                            width: auto;
                            margin-right: 0.6rem;
                            margin-bottom: 0.8rem;
                        }
                    }
                }
            }
        }
    }

    .section-details-light {
        background: #eee;
        max-width: 100%;
        overflow: hidden;

        .opc-tabs {
            &[data-op-type="accordion"] {
                margin-top: 2rem;
            }

            &[data-op-type="tabs"] {
                .tab-list {
                    border-bottom: none;

                    &::before,
                    &::after {
                        border-bottom: none;
                    }

                    li {
                        border: none;
                        color: #000;

                        &.is-active {
                            color: #000;
                            background: #eee;
                            border-bottom: none;

                            &::after {
                                display: none;
                            }
                        }

                        a {
                            font-size: 1.6rem;
                        }
                    }
                }

                .tab-content-wrapper {
                    margin-bottom: 4rem;
                }
            }
        }
    }

    .section-details-default {
        background: #fff;
        max-width: 100%;
        overflow: hidden;

        .opc-tabs {
            &[data-op-type="tabs"] {
                .tab-list {
                    border-bottom: none;
                    background: #eee;

                    &::before,
                    &::after {
                        border-bottom: none;
                        background: inherit;
                    }

                    li {
                        border: none;
                        color: #000;

                        &.is-active {
                            color: #000;
                            background: #fff;
                            border-bottom: none;

                            &::after {
                                display: none;
                            }
                        }

                        a {
                            font-size: 1.6rem;
                        }
                    }
                }

                .tab-content-wrapper {
                    margin-bottom: 4rem;
                }
            }
        }
    }

    .section-details-specs {
        ul.artSpec {
            margin: 0;
            padding: 0;
            list-style-type: none;

                   li.artSpecAttr {
                        line-height: 2.2rem;
                        padding: 1rem;
                        font-size: 1.6rem;
                        color: #5f5e5e;
                        display: flex;

                        &:nth-child(odd) {
                            background: $lightgray;
                        }

                        .label {
                            font-weight: 700;
                            margin: 0;
                            flex-basis: 50%;
                        }

                        .value {
                            flex-basis: 50%;
                        }
                    }
                
            
        }
    }

    .section-details-variations {
        .opc-option-selection {
            .label {
                font-size: 2rem;
                font-weight: 700;
            }

            .opc-product-article-selection {
                margin: 0;

                ul.product-article-selection-options {
                    list-style: none;
                    margin: 0;
                    margin-left: -1rem;
                    margin-right: -1rem;
                    padding: 0;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;

                    li.option-item {
                        height: auto;
                        //width: calc(calc(100% / 5) - 2rem);
                        width: 50%;
                        padding: 1rem;
                        @include mq($min-width: map-get($breakpoints, xs)) {
                            width: 15.6rem;
                            margin: 1rem;
                            padding: 0;
                        }
                        border: 2px solid transparent;

                        a {
                            text-align: center;
                            color: #000;
                            font-size: 1.6rem;
                            margin: 0;
                            padding: 0;
                            width: 100%;

                            .option-item-image {
                                height: 10rem;
                                overflow: hidden;
                                position: relative;

                                img {
                                    max-height: none;
                                    width: 100%;
                                }

                                &::before {
                                    content: " ";
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                    background-image: url(/webportal/config/layout/themes/project/assets/img/add-circular-button.svg);
                                    background-size: 50px;
                                    background-position: center;
                                    background-repeat: no-repeat;
                                    opacity: 0;
                                    transition: opacity 0.4s ease-in-out;
                                }
                            }

                            .option-item-text {
                                line-height: 2rem;
                                padding: 1rem 0;
                                > span {
                                    color:$brand-color-1;
                                    font-weight: bold;
                                }
                            }
                        }

                        &:hover {
                            border-color: #000;

                            a {
                                .option-item-image {
                                    &::before {
                                        opacity: .6;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    .section-details-action {
        padding-top: 0;

        .opc-price {
            margin-bottom: 2rem;

            &.is-basis {
                .price-basis {
                    font-size: 2.8rem;

                    .price {
                        order: 2;
                    }
                }
            }

            &.is-service {
                .price-basis {
                    font-size: 2rem;
                    font-weight: 400;
                }

                .price-service {
                    font-size: 2.8rem;
                }
            }

            .price-info {
                display: flex;
                align-items: center;
            }
        }

        .article-actions {
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;

            @include mq($min-width: map-get($breakpoints, s)) {
                //flex-wrap: nowrap;
            }

            @include mq($min-width: map-get($breakpoints, m)) {
                //flex-wrap: wrap;
            }

            .opc-checkbox {
                display: flex;
                align-items: center;
            }

            .opc-basket-button,
            .opc-favorite-button,
            .opc-compare-button,
                {
                margin-bottom: 1rem;
                margin-right: 0;
                flex-grow: 1;

                @include mq($min-width: map-get($breakpoints, s)) {
                    flex-grow: 0;
                    margin-right: 1rem;
                }
            }

            .opc-basket-button {
                width: 100%;
                margin: 0;
                display: flex;
                flex-wrap: wrap;

                @include mq($min-width: map-get($breakpoints, s)) {
                    width: auto;
                }

                @include mq($min-width: map-get($breakpoints, m)) {
                    //width: auto;
                }

                .opc-button {
                    //margin-left: 1rem;
                    //flex-grow: 1;
                    //width: 100%;
                    flex-grow: 1;
                    width: 100%;

                    @include mq($min-width: map-get($breakpoints, s)) {
                        width: auto;
                    }

                    @include mq($min-width: map-get($breakpoints, s)) {
                        //flex-grow: 0;
                    }
                }

                .basket-button-dim-1 {
                    margin: 0 auto;
                    margin-bottom: 1rem;

                    @include mq($min-width: map-get($breakpoints, s)) {
                        margin: 0;
                    }
                }
            }

            @include mq($min-width: map-get($breakpoints, m)) {}
            &.custshop {
                flex-wrap: nowrap;
                > a {
                    margin-right: 2rem;
                }
            }
        }
    }

    .saledetails {
        .labelbig {
            font-size: 2rem;
            font-weight: 700;
            line-height: 4rem;
            margin: 1rem 0 .6rem;
        }

        margin: 0 0 1rem;

        .noborder tr {
            border: none;
        }

        .priceview {
            margin: 0;
            padding: 0;
            list-style-type: none;
            width: 100%;
            display: flex;
            width: 100%;
            flex-wrap: wrap;

            li {
                flex-basis: 50%;
                display: flex;
                line-height: 2.2rem;
                color: $gray;
                padding: 1rem;
                font-size: 1.6rem;
                border-bottom: 1px solid $lightgray;

                // &:nth-child(4n+1){
                //     background: $lightgray;
                // }
                // &:nth-child(4n+2){
                //     background: $lightgray;
                // }
                .label {
                    font-weight: 700;
                    flex-basis: 50%;
                }

                .value {
                    flex-basis: 50%;
                    text-align: right;
                }
            }
        }

        .labelsmall {
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 4rem;
            margin: 2rem 0 0;
            display: block;
        }
    }
    .page-cms{
        #opaccCMS{
            margin: 0;
        }
    }
}

.spec-wrapper {
    padding-top: 2rem;

    [data-op-type="accordion"] & {
        padding-top: 0;
    }

    .opc-art-spec {
        display: flex;
        margin-left: -$_opc-grid-gutter-width;
        margin-right: -$_opc-grid-gutter-width;
        width: calc(100% + ($_opc-grid-gutter-width * 2));
        flex-wrap: wrap;

        .art-spec-group {
            padding: $_opc-grid-gutter-width;
            width: 100%;


            @include mq($min-width: map-get($breakpoints, s)) {
                width: 50%;
            }

            @include mq($min-width: map-get($breakpoints, m)) {
                width: 33.3%;
            }
        }
    }
}


.opc-favorite-button,
.opc-compare-button {
    opacity: .8;
    margin-left: 1px;
    background: $brand-color-1;
    opacity: .9;

    &:hover {
        background: $brand-color-1;
        opacity: 1;
    }
}

.opc-social-media-share {

    .share-content-wrapper {
        .social-media-icons {
            padding:0;
            display: flex;
            > li {
                list-style-type: none;
                transition: all .2s ease-in-out;
                > a {
                    background-color: rgb(102, 102, 102);
                    width: 4rem;
                    height: 4rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .icon-mailshare {
                    background-image: inline-svg($op-icon-mailshare-svg, '#222');
                    width: 3rem;
                    height: 3rem;
                    display: block;
                }
                .icon-whatsappshare {
                    background-image: inline-svg($op-icon-whatsappshare-svg, '#222');
                    width: 3rem;
                    height: 3rem;
                    display: block;
                }
                &:first-child {
                    padding-right: .5rem;
                }
                &:hover {
                    transform: translateY(-4px);
                }
            }
        }
    }
}


