/*###############################################

@Title: Sections
@Description:


###############################################*/


// namespace vars
$section-padding: 4rem;
// section layout styles




.section-wrapper {
    @extend %mw;
    max-width: 100%;
    overflow: hidden;
	padding-top: $section-padding / 3;
    padding-bottom: $section-padding / 3;
    padding: $section-padding / 3;

    &.overflow {
        overflow:initial;
    }
    
    @include mq($min-width: map-get($breakpoints, s)) {
        padding-top: $section-padding / 2;
        padding-bottom: $section-padding / 2;
    }

    @include mq($min-width: map-get($breakpoints, m)) {
        padding-top: $section-padding / 1.5;
        padding-bottom: $section-padding / 1.5;
    }

    @include mq($min-width: map-get($breakpoints, l)) {
        padding-top: $section-padding;
        padding-bottom: $section-padding;
    }
    
    &.no-padding {
        padding-top: 0;
        padding-bottom: 0;
    }
    
	.section-inner {
		@extend %mw-inner;
		@include clearfix();
		
        
        // used for aside with content block
		.section-inner-row {
            
			@include mq($min-width: map-get($breakpoints, m)) {
				@include break;
				@include susy-clearfix;
				margin-left: 0;
				margin-right: 0;
			}
        
            .section-inner-aside {
                width: 100%;
                margin-bottom: 2rem;
                @include mq($min-width: map-get($breakpoints, m)) {
                    margin-bottom: 0;
                    @include span(3);
                    padding-left: 0;

                    &.aside-sticky {
                        position: sticky;
                        top:2rem;
                    }
                }
            }
            .section-inner-content {
                width: 100%;
                @include mq($min-width: map-get($breakpoints, m)) {
                    @include span(9);
                    padding-left: .5rem;
                    padding-right: 0;
                }   
            }
            .collection{
                margin-bottom: 2rem;
                .red-line {
                    top: 3rem;
                }
                li{
                    padding: 1rem;
                    .article-list-item{
                        background: none;
                        //background: $gray;
                        // padding: 1.5rem;
                        // box-shadow: 0 0 1rem rgba($black,.15);
                        .article-list-item-image{
                            margin-bottom: 0;
                            //height: 16rem;
                        }
                        .article-list-item-details{
                            margin-bottom: 0;
                            .article-list-item-title{
                                margin-bottom: 0;
                                a{
                                    text-align: left;
                                    line-height: 50px;
                                    max-height: 50px;
                                    overflow: hidden;
                                    font-weight: normal;
                                    position: relative;
                                    font-size: 1.5rem;
                                    font-weight: bold;
                                    display: block;
                                    > span {
                                        padding: 0 1rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
		}
		
		.section-inner-content {
			
		}
	}
}

