@import "contentbox.scss";
@import "list-filter.scss";
@import "list-info.scss";
@import "article-list-menu.scss";
@import "opc-article-list.scss"; 
@import "opc-search"; 
@import "opc-breadcrumb"; 
@import "opc-favorite-modal"; 
@import "opc-compare-button"; 
@import "opc-selection-list"; 
@import "opc-carousel"; 
@import "cab-navigation"; 
@import "opc-navigation"; 
@import "opc-hero-image-slider"; 
@import "opc-connect-type-tabs"; 
@import "opc-basket-info"; 
@import "opc-basket-button"; 
@import "cab-cms"; 
@import "opc-filters";
@import "slick-slider";
@import "opc-category-navigation";
@import "opc-basket-summary";
@import "opc-direct-search-suggest";
@import "opc-category-item-price";
@import "daterangepicker";
                     



/*###########################################

 opc-search Overwrites

###########################################*/


.opc-search {
	
	position: fixed;
	top: 0; 
	left: 0; 
	right: 0;
	bottom: 0;   
	z-index: 20000;
	
	visibility: hidden;
	opacity: 0;
	z-index: -100;
	
	&.is-open {
		visibility: visible;
		opacity: 1;
		z-index: 20000;
	}
	
	.search-toggle {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 3;
    display: none;
	}
	.opc-search-bg {
		background: rgba(#fff, 0.98);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1; 
		display: block;
	}
	.opc-search-content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		padding: 2rem;
		
	}
	
	
	
	
	
	
	
	
	
	

	@include mq($min-width: map-get($breakpoints, m)) {
		position: relative;
		width: 100%;
		top: auto;
		left: auto;
		right: auto;
		bottom: auto;
		background: none;
		visibility: visible;
		opacity: 1;
		z-index: 20000;
		
		
		.opc-search-bg,
		.opc-search-toggle {
			display: none;
			
		}
		
		.opc-search-content {
			position: relative;
			padding: 0;
		}
		
		
		
	}
	
	
	
	
}