 /*###############################################

@Title: Direct Search Suggest
@Description:
This file contains the directsearch suggest styles

###############################################*/

 .opc-directsearch-suggest {
     display: flex;
     width: 100%;
     flex-wrap: wrap;
     background: #fff;
     position: relative;
     z-index: 2;
     .directsearch-suggest-row {
         display: flex;
         width: 100%;
         max-width: 100%;
         flex-wrap: wrap;
     }
     .directsearch-suggest-column {
         min-width: 30rem;
         flex-grow: 1;
         align-content: flex-start;
         &:empty {
             display: none !important;
         }
         .directsearch-suggest-column-section {
             margin-bottom: 2rem;
             .column-section-title {
                 @extend .opc-mini-label;
             }
             .column-section-list {}
             &:last-of-type {
                 margin-bottom: 0;
             }
         }
         .searchTerm {
             background: rgba(orange, 0.5);
         }
         .CatItem-ArtName1 {
             font-weight: bold;
         }
         .CatItem-ArtNo {}
         .CatItem-ArtInternalNo {}
         .Url-Canonical {}
         .CatStruct-Name {
             font-weight: bold;
         }
         .Url-Main {}
         .CatStruct-Name {
             
         }
         .CatStruct-Number {display:none;}
         .list-item-image {
             width: 8rem;
             height: 8rem;
             margin-right: 1rem;
             flex-shrink: 0;
             img {
                 margin: 0 auto;
                 display: block;
                 max-width: 100%;
                 max-height: 100%;
                 flex-shrink: 0;
             }
         }
         .list-item-info { width: 100%; max-width: 100%; }
         a {
             color: #000;
         }
         .list-item-name {
             font-weight: 400;
             margin-bottom: .6rem;
        }
        .list-item-additional-name {}
        .list-item-art-nr {
            display:none;
            opacity: 0.6;
            margin-bottom: .6rem;
        }
         .list-item-description {
             display:block;
             white-space: nowrap;
             text-overflow: ellipsis;
             //max-height: 4rem;
             position: relative;
             overflow: hidden;
             max-width: 100%;
             .spec-list{
                 > span{
                     &::after{
                         content: ",";
                     }
                     &:last-child{
                         &::after{
                             content: " ";
                         }
                     }
                 }
             }
            }
         .list-item-price {
             display: none;
             .price-basis {}
             .price-promo {}
         }
     }
     h3 {
         @extend h4;
         color: $brand-color-3;
         margin-bottom: 0.2em;
         font-size: 1.2rem;
         text-transform: uppercase;
     }
     &[data-op-type="2col3sec"] {
         .directsearch-suggest-column {
             width: 50%;
             //max-width: 50%;
             flex-shrink: 0;
             padding: 2rem;
             &:empty {
                 display: none;
             }
         }
     }
     &[data-op-type="3col2sec"] {
         .directsearch-suggest-column {
             width: 33.333%;
             flex-shrink: 0;
             padding: 2rem;
             &:empty {
                 display: none;
             }
         }
     }
     &[data-op-type="3rowA2sec"] {
         .directsearch-suggest-column {
             width: 50%;
             flex-shrink: 0;
             padding: 2rem;
             &:empty {
                 display: none;
             }
         }
     }
 }