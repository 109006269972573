.opc-form-field{
    background: $lightgray;
    .page-account & {
        background: transparent;
    }
    padding: 1rem;
    margin-bottom: 1rem;
    .form-field-label{
        padding-left:.5rem;
        font-weight: 400;
    }
    .form-field-element {
        width: 100%;
        input,
        select,
        textarea{
            background: transparent;
            padding-left:.5rem;
            border: none;
            border-bottom: 1px solid rgb(204, 204, 204);
        }
        input,
        select{
            height: 2rem;
            line-height: 2rem;
        }
        textarea{
            height: 10rem;
            line-height: 2rem;
            border: 1px solid rgb(204, 204, 204);
            padding: .4rem .6rem;
        }
        select{
            background-image: inline-svg($op-icon-angle-down-svg, '#000');
            background-position: calc(100% - 1rem) -.5rem;
            background-size: 2rem;
            background-repeat: no-repeat;
        }
    }
    &.form-field-accept-terms-addr{
        background: none;
    }
    .ff-error {
        font-size: 1.6rem;
    }
}

.orderform{
    .artinfo{
        img{
            max-width: 100%;
        }
    }
    h3{
        font-size: 2rem;
        font-weight: 700;
        margin: 1.5rem 0 3rem;
    }
}

.remark{
    font-size: 1.2rem;
    font-style: italic;
    margin-left: .5rem;
}