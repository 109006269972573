

.opc-search {
	
	position: relative;
	width: 100%;

	.opc-search-bg {
		
	}
	.opc-search-content {
        
        .opc-button[data-op-action="close"] {
            
            @include mq($min-width: map-get($breakpoints, m)) {
                display: none;
            }
        }

		.opc-search-query {
            border: 1px solid #ccc;  
            display: flex;
            padding: 0.5rem 0;
            background: $white;
            
            .search-query {
                border: none;
                height: 3rem;
                padding-left: 1rem;
                padding-right: 1rem;
                &:focus {
                    outline:none;
                }
            }  
            .search-filter {
                border: none;
                border-left: 1px solid #ccc;
                border-right: 1px solid #ccc;
                padding: 0 1rem;
                height: 3rem;
                
                &:focus {
                    outline:none;
                }
            }
            .search-btn {
                background: none;
                border: none;
                height: 3rem;
                width: 4rem; 
                flex-shrink: 0;
                color: $brand-color-1;
            }
		}
		
		.opc-search-results {
			display: none;
            padding: 0;
            position: relative;
            top: 0;

            @include mq($min-width: map-get($breakpoints, m)) {
               position: absolute;
               top: 100%;
            } 

            .opc-directsearch-message {
                height: 4rem;
                position: relative;
                z-index: 2;
                background: #eee;
                margin: 0.5rem;
                display: flex; 
                align-items: center;
                justify-content: center;
            }

            a{
                height: auto;
            }

		} 
		
		
	}
	
	

	input {
		width: 100%;
	}
}



.column-section-list {
    list-style: none;
    margin: 0; 
    padding: 0;   

    li {
        border-bottom: 1px solid #eee;
        a {
            display: block; 
            padding: 1.5rem 0;  

            &:hover { 
                background: #eee;
            }

            > span {
                display: block;
            }
             
 
            
        }
    }

    &[data-op-type="article-image"] {
        .list-item-image {
            width: 8rem;
            margin-right: 1rem;
        }
        .list-item-info {
            width: calc(100% - 9rem);
        }
        .list-item-description {
            max-width: 100%;
        }


        a {
            display: flex;
            width: 100%;
            padding: 1rem 0;

            .list-item-image {
                //background: #ccc;
                margin-right: 1rem;
                display: flex;
                align-items: center;
                justify-content: center; 
            }
            .list-item-info {
                display: flex;
                flex-direction: column;
                justify-content: center; 
                .name {
                    display: block;
                    font-weight: 600; 
                    margin-bottom: 0.2em;  
                }
                .additional-name {
                    display: block;
                    font-size: 1.2rem; 
                    opacity: 0.8;
                    margin-bottom: 0.2em;
                } 
                .description {
                    display: block;
                }
                .price {

                }



            }


        }
        



    }

    &[data-op-type="simple"] {
        a {
            position: relative;
        }
        .CatStruct-Name {
             
        }
        .CatStruct-Name ~ .Amount {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background: $brand-color-1;
            color: #fff;
            border-radius: 5px;
            display: block;
            padding: 0.25rem 0.5rem;
        }
        .CatStruct-Number {display:none;}
    }

    .moreItems {
        a {
            padding: 1rem;
            font-weight: bold;
            
            span {
                margin: 0;
                font-size: 1.2rem;
            }
        }
    }
} 