/*###############################################

@Title: Option Selection
@Description:
This file contains the option selection styles

###############################################*/

$_opc-option-selection-border : 1px solid #ccc !default;
$_opc-option-selection-border-hover : 1px solid #999 !default;
$_opc-option-selection-border-selected : 1px solid $brand-color-1 !default;
$_opc-option-selection-color : #999 !default;
$_opc-option-selection-color-selected : $brand-color-1 !default;

.opc-option-selection {
    margin-bottom: 4rem;
    position: relative;
    z-index: 10;
    .option-selection-item,
    .option-selection-select {
        vertical-align: middle;
        display: inline-block;
        height: map-get($heights, 'normal');
        line-height: map-get($heights, 'normal');
        cursor: pointer;
        margin-right: 1rem;
        padding: 0 2rem;
        border: $_opc-option-selection-border;
        color: $_opc-option-selection-color;
        &:hover {
            border: $_opc-option-selection-border-hover;
        }
        &.is-selected {
            border: $_opc-option-selection-border-selected;
            color: $_opc-option-selection-color-selected;
        }
    }
    .option-selection-select {
        padding: 0 1rem;
    }
    .option-selection-group {
        margin-right: 1rem;
        vertical-align: middle;
        display: inline-block;
        label,
        .label,
        .option-selection-group-label {
            font-weight: 600;
            margin-right: 0.5rem;
            margin-bottom: 0.5rem;
            display: inline-block;
        }
        &[data-op-type="image"] {
            .option-selection-item {
                height: 10rem;
                img {
                    max-height: 100%;
                }
            }
        }
        &[data-op-type="color"] {
            .option-selection-item {
                border-color: transparent;
                &.is-selected {
                    position: relative;
                    &::before {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translateY(-50%) translateX(-50%);
                        display: block;
                        color: #fff;
                        text-shadow: 0 0 1px #000;
                    }
                }
            }
        }
    }
    .opc-prodart-selection {
        .opc-selection {
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                align-items: center;
                li {}
            }
        }
    }
}