/*###############################################

@Title: Page Account
@Description:


###############################################*/

.page-account {
    .section-inner-aside {
        display: none;
        @include mq($min-width: map-get($breakpoints, m)) {
            display: block;
        }
    }
    h2 {
        position: relative;
        .opc-button {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .opc-navigation[data-op-navtype="simple"] {
        .navigation-level-1 {
            >li {
                margin-bottom: 2rem;
                >a {
                    &::before {
                        display: none;
                    }
                }
                &.is-active {
                    >a {
                        font-weight: bold;
                    }
                }
            }
        }
        .navigation-level-2 {
            >li {
                >a {
                    padding-left: 1rem;
                }
                &:hover {
                    >a {
                        background: #f8f8f8;
                    }
                }
                &.is-active {
                    >a {
                        font-weight: bold;
                        background: #eee;
                    }
                }
            }
        }
    }
}

// list for order details
.last-orders-details-list {
    .item-list-head {
        display: none;
        @include mq($min-width: map-get($breakpoints, m)) {
            display: flex;
            .list-item-column {}
        }
    }
    .list-item {
        flex-wrap: wrap;
        // padding-top: 1rem;
        // padding-bottom: 1rem;
        // @include mq($min-width: map-get($breakpoints, m)) {
        //     flex-wrap: nowrap;
        // }
    } // style of the cols
    .list-item-column {
        width: 100%;
        padding: 0 0 1rem;
        display: flex;
        @include mq($min-width: map-get($breakpoints, m)) {
            padding: 0.5rem 2rem 0 0;
        }
        .opc-button {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
        &:nth-child(1) {
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 55%; //flex-shrink: 0;
                flex-grow: 1;
            }
        }
        &:nth-child(2) {
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 15%;
                flex-shrink: 0; //flex-grow: 1;
            }
        }
        &:nth-child(3) {
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 15%;
                flex-shrink: 0;
                justify-content: flex-end;
            }
        }
        &:nth-child(4) {
            @include mq($min-width: map-get($breakpoints, m)) {
                text-align: right;
                width: 15%;
                flex-shrink: 0;
                justify-content: flex-end;
            }
        }
        // &:nth-child(5) {
        //     .opc-button {
        //         margin-bottom: 0.5rem;
        //         font-size: 1.2rem;
        //     }
        //     justify-content: flex-end;
        //     @include mq($min-width: map-get($breakpoints, m)) {
        //         text-align: right;
        //         width: 20%;
        //         width: 18rem;
        //         flex-shrink: 0;
        //         justify-content: flex-end;
        //         padding-right: 0;
        //         flex-wrap: nowrap;
        //     }
        // }
        .list-item-label {
            flex-shrink: 0;
            display: block;
            width: 35%;
            @include mq($min-width: map-get($breakpoints, m)) {
                display: none;
            }
        }
    }
    .row1,
    .row2{
        flex-basis: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: .5rem 0;
    }
    .row2{
        justify-content: space-between;
        align-items: center;
        .opc-checkbox{
            flex-grow: 0;
        }
        .opc-basket-button{
            flex-grow: 1;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            margin-top: 1rem;
            .opc-button[data-op-btnaction="add"]{
                width: 100%;
                margin: 1rem 0;
            }
            @include mq($min-width: map-get($breakpoints, s)) {
                margin-top: 0;
                .opc-button[data-op-btnaction="add"]{
                    width: auto;
                    margin: 0;
                }
            }
        }
    }
}

// lieferadressen
.shipping-address-list {
    .item-list-head {
        display: none;
        @include mq($min-width: map-get($breakpoints, xs)) {
            display: flex;
        }
    }
    .list-item {
        flex-wrap: wrap;
        position: relative;
        @include mq($min-width: map-get($breakpoints, xs)) {
            flex-wrap: nowrap;
        }
        .button-text-edit {
            display: block;
        }
        .button-text-save {
            display: none !important;
        }
        .delete-list-item {
            display: inline-flex;
        } // styles for edit mode
        &.is-changing {
            .button-text-save {
                display: block !important;
                @include mq($min-width: map-get($breakpoints, xs)) {
                    display: none !important;
                }
            }
            .button-text-edit {
                display: none !important;
            }
            .delete-list-item {
                display: none !important
            }
        }
    } // style of the cols
    .list-item-column {
        width: 100%;
        padding: 0.5rem 0 0.5rem;
        @include mq($min-width: map-get($breakpoints, xs)) {
            padding: 0.5rem 2rem 0 0;
        }
        .opc-button {
            padding-left: 0.5rem;
			padding-right: 0.5rem;
			width: 100%;

			@include mq($min-width: map-get($breakpoints, xs)) {
				width: unset;
			}
        }
        &:nth-child(1) {
			border-bottom: 1px solid #eee;
            @include mq($min-width: map-get($breakpoints, xs)) {
				border-bottom: none;
                flex-grow: 0;
                width: 10%;
                min-width: 8rem;
            }
        }
        &:nth-child(2) {
			border-bottom: 1px solid #eee;
			
            @include mq($min-width: map-get($breakpoints, xs)) {
				border-bottom: none;
                width: 25%;
                flex-grow: 0;
            }
        }
        &:nth-child(3) {
			border-bottom: 1px solid #eee;
            @include mq($min-width: map-get($breakpoints, xs)) {
				border-bottom: none;
                width: 20%;
                flex-grow: 1;
            }
        }
        &:nth-child(4) {
			text-align: left;
			margin-bottom: 2rem;
            .opc-button {
                margin-bottom: 0.5rem;
                font-size: 1.2rem;
                border: 1px solid $brand-color-2;
                svg {
                    margin-right: 1rem !important;
                    &:only-child {
                        margin-right: 0 !important;
                    }
                }
                span {
                    display: block;
                }
            }
            flex-wrap: wrap;
            display: flex;
            flex-direction: column;
            padding-top: 1rem;
            @include mq($min-width: map-get($breakpoints, xs)) {
				margin-bottom: 0;
                flex-direction: row;
                .opc-button {
                    flex-grow: 1;
                    margin-right: 1rem;
                    &:last-child,
                    &:only-child {
                        margin-right: 0;
                    }
                }
            }
            @include mq($min-width: map-get($breakpoints, xs)) {
                text-align: right;
                width: 30rem;
                padding-right: 0;
                flex-direction: row;
                position: static;
                display: block;
                width: 10%;
                flex-grow: 0;
                min-width: 10rem;
                text-align: right;
                .opc-button {
                    margin-right: 0;
                    border: none;
                    flex-shrink: 0;
                    span {
                        display: none;
                    }
                }
            }
        }
        .list-item-label {
            display: inline-block;
            width: 35%;
            @include mq($min-width: map-get($breakpoints, xs)) {
                display: none;
            }
        }
        .list-item-value{
            flex-grow: 1;
            display: flex;
            flex-direction: row;
        }
        .opc-form-field {
            margin-bottom: 0;
        }
    }
}

// meine warenkoerbe
.last-baskets-list {
    .item-list-head {
        display: none;
        @include mq($min-width: map-get($breakpoints, m)) {
            display: flex;
        }
    }
    .list-item {
        flex-wrap: wrap;
        position: relative;
        padding-top: 1rem;
        padding-bottom: 1rem;
        @include mq($min-width: map-get($breakpoints, m)) {
            flex-wrap: nowrap;
        }
        &:hover{
            padding-top: 1rem!important;
            padding-bottom: 1rem!important;
        }
    } // style of the cols
    .list-item-column {
        width: 100%;
        padding: 0.5rem 0 0.5rem;
        @include mq($min-width: map-get($breakpoints, m)) {
            padding: 0.5rem 2rem 0 0;
        }
        .opc-button {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
        //&:nth-child(1) {
        &.docnr {
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 15%;
                flex-shrink: 0;
            }
        }
        //&:nth-child(2) {
        &.date {
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 20%;
                flex-shrink: 0;
                flex-grow: 1;
            }
        }
        //&:nth-child(3) {
        &.keyword {
            display: none;
        }
        //&:nth-child(4) {
        &.price {
            @include mq($min-width: map-get($breakpoints, m)) {
                text-align: right;
                width: 20%;
                flex-shrink: 0;
            }
        }
        //&:nth-child(5) {
        &.action {
            .opc-button {
                margin-bottom: 0.5rem;
                font-size: 1.2rem;
            }
            flex-wrap: wrap;
            display: flex;
            flex-direction: column;
            padding-top: 0;
            @include mq($min-width: 380px) {
                //position: absolute;
                // right: 0;
                // top: 1rem;
                width: 100%;
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                text-align: right;
                width: 30rem;
                padding-right: 0;
                padding-top: .5rem;
                display: block;
                position: static;
            }
        }
        .list-item-label {
            display: inline-block;
            width: 40%;
            @include mq($min-width: map-get($breakpoints, m)) {
                display: none;
            }
        }
    }
}
//.last-baskets-list.type1 {
.last-baskets-list {
    .list-item-column {
        &.docnr {
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 10%;
            }
        }
        &.date {
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 15%;
            }
        }
        &.keyword {
            display: block;
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 15%;
            }
        }
        &.price {
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 15%;
            }
        }
        &.action {
            @include mq($min-width: 380px) {
                width: 100%;
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 30rem;
            }
        }

    }
}

// meine bestellungen
.last-offers-list {
    .item-list-head {
        display: none;
        @include mq($min-width: map-get($breakpoints, m)) {
            display: flex;
        }
    }
    .list-item {
        flex-wrap: wrap;
        position: relative; ///margin: 1rem 0;
        padding-top: 1rem;
        padding-bottom: 1rem;
        @include mq($min-width: map-get($breakpoints, m)) {
            flex-wrap: nowrap;
        }
        &:hover{
            padding-top: 1rem!important;
            padding-bottom: 1rem!important;
        }
    } // style of the cols
    .list-item-column {
        width: 100%;
        padding: 0.5rem 0 0.5rem;
        @include mq($min-width: map-get($breakpoints, m)) {
            padding: 0.5rem 2rem 0 0;
        }
        .opc-button {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }



        //&:nth-child(1) {
        &.docnr {
            @include mq($min-width: map-get($breakpoints, tiny)) {
                //width: 20%;
                flex-shrink: 0;
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 15%;
                flex-shrink: 0;
            }
        }
        //&:nth-child(2) {
        &.date {
            @include mq($min-width: map-get($breakpoints, tiny)) {
                //width: 20%;
                flex-shrink: 0;
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 20%;
                flex-shrink: 0; //flex-grow: 1;
            }
        }
        //&:nth-child(3) {
        &.keyword {
            @include mq($min-width: map-get($breakpoints, tiny)) {
                //width: 20%;
                flex-shrink: 0;
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 10%;
                flex-shrink: 0;
            }
        }
        //&:nth-child(4) {
        &.price {
            @include mq($min-width: map-get($breakpoints, m)) {
                text-align: right;
                width: 20%;
                flex-shrink: 0;
            }
        }
        //&:nth-child(5) {
        &.action {
            .opc-button {
                margin-bottom: 0.5rem;
                font-size: 1.2rem;
            }
            flex-wrap: wrap;
            display: flex;
            flex-direction: column;
            padding-top: 1rem;
            @include mq($min-width: map-get($breakpoints, tiny)) {
                // position: absolute;
                // right: 0;
                // top: 0;
                width: 100%;
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                text-align: right;
                width: 30rem;
                padding-right: 0;
                flex-direction: row;
                position: static;
                display: block;
            }
        }
        .list-item-label {
            display: inline-block;
            width: 30%;
            @include mq($min-width: 400px) {
                //display: block;				
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                display: none;
            }
        }
    }
}

.account-user-data {
    margin-bottom: 2rem;
    h3 {
        margin-bottom: 0.5em;
        opacity: 0.6;
    }
    dl {
        font-size: 1.4rem; //margin-bottom: 2rem;
        border-bottom: 1px solid #eee;
        padding: 1rem 0 0;
        dt {
            font-size: 1em;
            margin-bottom: 0;
        }
        dd {
            padding: 1rem 0;
            min-height: 3.9rem;
            &.editmode {
                padding: 0;
            }
            .opc-form-field {
                margin: 0;
                margin-right: 3rem;
                .form-field-element {
                    input,
                    select {
                        border: none;
                        padding: 1rem 0;
                        font-weight: bold;
                        background: #f8f8f8;
                    }
                }
            }
        }
    }
    .edit-btn {
        position: absolute;
        right: 1.5rem;
        bottom: 1rem; //transform: translateY(-50%);
        //display: none;
    }
    .editmode {
        display: none;
    }
    .opc-col {
        &:hover,
        &.is-changing {
            .opc-button {
                display: block;
            }
        }
    }
    .op-icon-check {
        display: none;
    }
    .is-changing {
        dd {
            display: none;
        }
        .editmode {
            display: block;
        }
        .op-icon-edit {
            display: none;
        }
        .op-icon-check {
            display: block;
        }
    }
}

#filter-form {
    .opc-form-field-group{
        flex-wrap: wrap;
        .opc-form-field{
            width: 50%;
            margin: 0;
            flex-grow: 1;
            @include mq($min-width: map-get($breakpoints, s)) {
                width: auto;
            }
        }
        .opc-button {
            width: 100%;
            @include mq($min-width: map-get($breakpoints, s)) {
                margin-top: 24px;
                width: auto;
            }
        }
    }
}


.last-orders-details-list{
    .item-list-body{
        .list-item{
            .list-item-column{
                .list-item-value{
                    flex-grow: 1;
                    display: flex;
                    flex-direction: row;
                    .article-list-item-spec{
                        margin-left: 1rem;
                        flex-grow: 1;
                        display: flex;
                        flex-direction: row;
                        > div{
                            &::after{
                                content: ",";
                                margin-right: .5rem;
                            }
                            &:last-child{
                                &::after{
                                    content: "";
                                }
                            }
                        } 
                    }
                }
            }
        }
    }
}

.section-inner-content{
    > .addAddressInfo{
        margin-bottom: 1rem;
    }
}

.flatpickr-clear-button{
    background: $brand-color-1;
    color: #fff;
    margin: .5rem;
}