/*###############################################

@Title: Theme 
@Description:
This file contains all theme relevant files

###############################################*/

// import theme specific node modules or external stuff
@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,600i,700,800";
@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";

// import theme overwrites
@import "atoms/atoms";
@import "organisms/organisms";

@import "other/burger";
@import "other/slick";
@import "other/slick-theme";

@import "structure/grid";
@import "structure/topbar";
@import "structure/header";
@import "structure/mainnav";
@import "structure/offcanvasnav"; 
@import "structure/content";
@import "structure/footer";
@import "structure/section";

// page specific styles
@import "pages/checkout";
@import "pages/details";
@import "pages/register-login";
@import "pages/favorites";
@import "pages/compare";
@import "pages/basket";
@import "pages/account";
@import "pages/error";
@import "pages/offline";
@import "pages/start";
@import "pages/start";
@import "pages/toolbar";
  
* { 
	box-sizing: border-box;
}   


                   