.slick-slider{
    margin-bottom: 30px;
    position: relative;
    .slick-arrow{
        z-index: 1001;
        font-size: 55px;
        line-height: 55px;
        width: 55px;
        height: 55px;
        display: none;
        @include mq($min-width: 1140px) {
            display: block;
        }
        &::before {
            background-size: 55px;
            display: inline-block;
            content: '';
            width: 55px;
            height: 55px;
            opacity: 1
        }
        &.slick-prev {
            left: 45px;
            &::before {
                background-image: url(/webportal/config/layout/themes/project/assets/img/left-arrow-lt.svg);
            }
        }
        &.slick-next {
            right: 45px;
            &::before {
                background-image: url(/webportal/config/layout/themes/project/assets/img/right-arrow-gt.svg);
            }
        }
    }
    // Dots
    .slick-dots
    {
        position: absolute;
        bottom: 20px;
        display: block;
        width: 100%;
        padding: 0;
        list-style: none;
        text-align: center;
        li {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            padding: 0;
            cursor: pointer;
            button {
                font-size: 0;
                line-height: 0;
                display: block;
                width: 20px;
                height: 20px;
                padding: 5px;
                cursor: pointer;
                color: transparent;
                border: 0;
                outline: none;
                background: transparent;
                &:hover,
                &:focus {
                    outline: none;
                    &::before{
                        opacity: 1;
                    }
                }
                &::before {
                    font-family: 'slick';
                    font-size: 6px;
                    line-height: 20px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    content: '.';
                    text-align: center;
                    opacity: .25;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    color: white;
                    font-size: 40px;
                    line-height: 10px;
                    content: '\25CB';
                    opacity: 1;
                }
            }
            &.slick-active{
                button:before {
                    opacity: .75;
                    color: white;
                    content: '\25CF';
                }
            }
        }
    }
    
}
.artpool-slider{
    .slider-item{
        height: 30rem;
        @include mq($min-width: 768px) {
            height: 63rem;
        }
        @include mq($min-width: 979px) {
            //height: 920px;
            height: 75rem;
        }
        overflow: hidden;
        .slider-item-image{
            background-position: center center;
            background-size: contain;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
        }
        .slider-item-content{
            .slider-item-content-inner{
                
            }
        }
    }
}

.kat-slider{
    .slick-arrow{
        overflow: hidden!important;
        &.slick-prev {
            left: -110px;
        }
        &.slick-next {
            right: -110px;
        }
    }
    // Fix
    .category-item-image{
        background: transparent!important;
    }
}
.gray-section{
    .kat-slider{
        .slick-arrow{
            &.slick-prev {
                &::before{
                    background-image: url(/webportal/config/layout/themes/project/assets/img/left-arrow-lt-white.svg);
                }
            }
            &.slick-next {
                &::before{
                    background-image: url(/webportal/config/layout/themes/project/assets/img/right-arrow-gt-white.svg);
                }
            }
        }
    }
}

.testimonial-wrapper{
    position: relative;
    .slick-dots
    {
        bottom: -45px;
        li {
            button {
                &:hover,
                &:focus {
                    &::before{
                    }
                }
                &::before {
                    color: #5f5e5e;
                }
            }
            &.slick-active{
                button:before {
                    color: #5f5e5e;
               }
            }
        }
    }
}