/*###############################################

@Title: Offcanvas Nav
@Description:


###############################################*/


/*
 Types:
 - full: for a fullscreen menu
 - side: for a side menu that moves the content to the right 
*/
$offcanvas-type: 		'side';  // side or full
$offcanvas-width:		100%;		// width of side menu
$offcanvas-color:		#fff;

.offcanvas-menu-wrapper {
	
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	background: #333;
	overflow-x: hidden;
	overflow-y: auto;
	transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
	
	@if $offcanvas-type == 'full' {
	
		@extend %mw;
		right: 0;
		// hide when not active
		visibility: hidden;
		z-index: -10;
		opacity: 0;
		
		// show when offcanvas-menu-open class is set to body
		.offcanvas-menu-open & {
			visibility: visible;
			z-index: 1000;
			opacity: 1;
		}		
		
		
	} @else if $offcanvas-type == 'side' {
		
		width: $offcanvas-width;
		right: -$offcanvas-width;
		left: auto;
		
		.offcanvas-menu-open & {
			transform: translateX(-100%);
			z-index: 1000;
		}	
	}
	
	.offcanvas-menu-inner {
		@extend %mw-inner; 

		.opc-mini-label {
			padding: 1rem 2rem 0;
			margin-bottom: 0.5rem;
			color: #fff;
			margin-top: 2rem;
			font-size: 1.2rem;
			font-weight: normal;

			&:first-child {
				margin-top: 0;
			}
		}

		display: flex;
		height: 100%;
		flex-direction: column;
	}

	.offcanvas-menu-head {
		background: #fff;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1rem 2rem 1rem;
		flex-shrink: 0;

		.offcanvas-brand {
			width: 100%;
			a {display:block;} 
			img {
				// width: 100%;
				max-width: 22rem;
				max-height: 9rem;
			}
		}

		.offcanvas-menu-close {
			background: transparent;
			color: $brand-color-1;;
			padding: 0;
			
			svg {
				margin: 0;
				font-size: 3.6rem;
			}
		}


	}
	.offcanvas-menu-body{
		margin-top: 2rem;
	}

	

	.offcanvas-group {
		margin-bottom: 2rem;
		color: #fff; 
		.offcanvas-favorites,
		.offcanvas-compare {
			a {
				display: block;
				color: rgba($offcanvas-color,0.8);
				padding: 2rem;
				font-weight: 600;
				font-size: 18px;
				&:hover {
					color: rgba($offcanvas-color,1);
				}
			}

			.opc-compare-count,
			.favorite-count {
				display: block;
				background: $brand-color-1;
				&::before,
				&::after {
					display: none;
				}
				position: absolute;
				right: 2rem;
				top: 50%;
				transform: translateY(-50%);
				width: 2.4rem;
				height: 2.4rem;
				font-size: 1.2rem;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				&:empty {
					display: none;
				}
			}
		}

		.offcanvas-lang {
			padding: 2rem;   
			display: inline-block;  
			.opc-language-selection {
				.language-selection-label {
					margin-bottom: 0.5rem;
					.current-selection {display:none;}
				}
				ul {
					position: static;
					display: flex;
					li  {
						border: none !important;
						a {
							padding: 0.5rem 0; 
							margin-right: 1rem;
							font-size: 18px;
						}
						&.is-active {
                            span {
                                opacity: 1;
                                color: $brand-color-1;
                                font-weight: 700;
                                padding: 0.5rem 0; 
                                margin-right: 1rem;
                                font-size: 18px;
                            }
							a {
								opacity: 1;
								color: #fff;
								font-weight: 700;
							}
						}
					}
				}
			}
		}
	}

	.offcanvas-item {
		a { 
			display: block;
			color: rgba($offcanvas-color,0.8);
			padding: 2rem 2rem 1rem;
			&:hover {
				color: rgba($offcanvas-color,1);
			}
		}
	}

	.offcanvas-group{
		.offcanvas-item {
			a{
				font-size: 18px;
				font-weight: 600;
			}
		}
	}

	.opc-navigation {
		color: #fff;  
		a {
			color: rgba($offcanvas-color,0.8);
			&:hover {
				color: rgba($offcanvas-color,1);
				background: rgba(#000, 0.1);
			}
			&.is-active {
				background: $brand-color-1;
			}
		}

		&[data-op-navtype="aside-click"] {
			.has-children {
				position: relative;
				.navigation-toggler {
					z-index: 2;
					top: 1.5rem;
					right: 2rem;
					width: 3rem;
					height: 3rem;
					position: absolute;
					display: block;
					background: rgba(#000, 0.4);
					border-radius: 0.5rem;
					cursor: pointer;
					transition: all 0.2s ease-in-out;
					&::after {
						content: '';
						position: absolute;
						right: 0.5rem;
						top: 0.5rem;
						width: 2rem;
						height: 2rem;
						display: block;
						background-image: inline-svg($op-icon-angle-down-svg, '#fff');
						background-position: center;
						background-size: 100%;
						background-repeat: no-repeat;
					}
				}
				&.is-open {
					//border-bottom: 1px solid #ccc;
					> .navigation-toggler {
						transform: rotate(180deg);
					}
				}
				&.is-active {
					> .navigation-toggler {
						//pointer-events: none;
					}
				}
			}

			.navigation-level-1 {            
				> li {
					margin-bottom: 0;
					> a {
						padding: 2rem 2rem 1rem;
						font-weight: 600;
						font-size: 18px;
					}
					&.is-open {
						background: rgba(#000, 0.1);
						> a {
							font-weight: bold;
							color: $offcanvas-color;
						}
						>.navigation-level-2 {
							display: block;
						}
					}
					&.is-active {
						> a {
							color: $brand-color-1;
						}
					}
				}
			}
			.navigation-level-2 {
				display: none;
				> li {
					border-bottom: none;
					> a {
						padding: 1rem;
						white-space: normal;
						font-size: 16px;
						margin-left: 25px;
					}
					&.is-open {
						> a {
							font-weight: bold;
							color: $offcanvas-color;
						}
						>.navigation-level-3 {
							display: block;
						}
					}

					&.is-active {
						> a {
							color: $brand-color-1;
						}
					}
				}
			}
			.navigation-level-3 {
				display: none;
				> li {
					> a {
						padding: 2rem;
					}
					&.is-open {
						> a {
							font-weight: bold;
							color: $brand-color-1;
							
						}
						>.navigation-level-4 {
							display: block;
						} 
					}
					&.is-active {
						> a {
							color: $brand-color-1;
						}
					}
				}
			}
			.navigation-level-4 {
				display: none;
				> li {
					> a {
						padding: 2rem;
					}
					&.is-open {
						> a {
							font-weight: bold;
							color: $offcanvas-color;
							padding: 2rem;
						}
						>.navigation-level-5 {
							display: block;
						}
					}
					&.is-active {
						> a {
							color: $brand-color-1;
						}
					}
				}
			}
		}
	}
}



.viewport { 

	@if $offcanvas-type == 'side' {
		transform: translateX(0);
		transition: all 0.2s ease-in-out;

		.offcanvas-menu-open & {
			transform: translateX(-$offcanvas-width);
		}	
	} 	
	z-index: 1;
	
}