/*#######################################################

@Title:       Opacc Autocomplete
@Description: This file contains the autocomplete styles

#######################################################*/

/*###############################################

@Title: Mixins
@Description:
This file sets the mixins for the core

###############################################*/
@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);
	@if $u1==$u2 and $u1==$u3 and $u1==$u4 {
		& {
			font-size: $min-font-size;
			@media screen and (min-width: $min-vw) {
				font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}
			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;
			}
		}
	}
}


/* *****************************************

SVG Inline Functions

***************************************** */

// Replace letters
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

// Encode symbols
@function url-encode($string, $color) {
    $map: (
        "%": "%25",
        "<": "%3C",
        ">": "%3E",
        //" ": "%20",
        "!": "%21",
        "*": "%2A",
        "'": "%27",
        '"': "%22",
        "(": "%28",
        ")": "%29",
        ";": "%3B",
        ":": "%3A",
        "@": "%40",
        "&": "%26",
        "=": "%3D",
        "+": "%2B",
        "$": "%24",
        ",": "%2C",
        "/": "%2F",
        "?": "%3F",
        "#": "%23",
        "[": "%5B",
        "]": "%5D"
    );

    $new: str-replace($string, 'height="32"', 'height="32" fill="#{$color}"');
    @each $search, $replace in $map {
        $new: str-replace($new, $search, $replace);
    }

    @return $new;
}

// Format the SVG as a URL
@function inline-svg($string, $color) {
    @return url('data:image/svg+xml,#{url-encode($string, $color)}');
}


$autocomplete-selected-bg: #eee;
$autocomplete-padding: 1rem;
$autocomplete-highlight-color: #fc8200 !default;
$autocomplete-image-width: 100px;
$autocomplete-image-height: $autocomplete-image-width;

$op-icon-preload-svg: '<svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="32" height="32" viewBox="0 0 128 128" xml:space="preserve"><g><linearGradient id="linear-gradient"><stop offset="0%" stop-color="#ffffff"/><stop offset="100%" stop-color="#000000"/></linearGradient><path d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z" fill="url(#linear-gradient)" fill-rule="evenodd"/><animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1080ms" repeatCount="indefinite"></animateTransform></g></svg>';
$_opc-preload-icon: inline-svg($op-icon-preload-svg, "#999") !default;

$op-icon-angle-down-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path style=" " d="M 4.21875 10.78125 L 2.78125 12.21875 L 15.28125 24.71875 L 16 25.40625 L 16.71875 24.71875 L 29.21875 12.21875 L 27.78125 10.78125 L 16 22.5625 Z "/></svg>';
$_opc-select-icon: inline-svg($op-icon-angle-down-svg, "#999") !default;


.opc-autocomplete {

	.opc-input[data-op-type="autocomplete"] {
		position: relative;

		background-image: $_opc-select-icon;
		background-size: 1.8rem;
		background-position: calc(100% - 1rem) center;
		background-repeat: no-repeat;
		padding-right: 3.5rem !important;
		&:hover {

		}
	}
	&.is-loading {
		.opc-input[data-op-type="autocomplete"] {
			background-image: $_opc-preload-icon;
		}
	}

	.form-field-list {
		display: block;
		// position: relative;
		overflow: hidden;
		border-top: none;
			.select-list {
				z-index: 1000;
				overflow: auto;
				box-sizing: border-box;
				max-height: inherit;
				li {
					display: flex;
				}
				.list-item-image {
					padding-right: $autocomplete-padding;
					max-width: $autocomplete-image-width;
					max-height: $autocomplete-image-height;
				}
				.list-item-name, .list-item-description {
					display: block;
				}
				.autocomplete-group {
					background: $autocomplete-selected-bg;
				}
				.selected,
				li:hover:not(.autocomplete-group) {
					background: $autocomplete-selected-bg;
					cursor: pointer;
				}
			}
		&.is-hidden {
			display: none;
		}
	}

	.autocomplete-highlight {
		font-weight: bold;
		color: $autocomplete-highlight-color;
	}
}