.opc-breadcrumb {
    display: none;
    max-width: $mw;
    margin-left: auto;
    margin-right: auto;
    line-height: 30px;
    height: 30px;
    padding: 10px 0;
    .breadcrumb-pretext{
        display: none;
    }
    @include mq($min-width: map-get($breakpoints, m)) {
        display: flex;
    }
    .breadcrumb-navigation {
        li{
            color: $gray;
            >a{
                color: $gray;
                &:hover{
                    color: $gray;
                }
            }
            &::after{
                width: 2em;
                height: 2em;
                background-image: inline-svg($op-icon-angle-right-svg, $gray);
            }
            &.is-active {
                font-weight: bold;
            }
        }
    }
} 
