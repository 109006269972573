/*###############################################

@Title: Page Register / Login
@Description:


###############################################*/

.page-register-login {
    h1 {
        position: absolute;
        visibility: hidden;
        height: 0;
        top: 0;
        overflow: hidden;
        pointer-events: none;
    }
    .opc-button.fullwidth {
        width: 100%;
        text-align: center;
    }
    .content-box {
        .content-box-body {
            .content-box-slide-content {
                display: block;
            }
        }
    }
}

.form-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}