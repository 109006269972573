

$_opc-table-padding: 1rem !default;


table {

    width: 100%;
    text-align: left;
    table-layout: fixed;
    border-collapse: collapse;

    thead {

        tr {

        }

        th {
            padding: $_opc-table-padding;
        }
        td {
            padding: $_opc-table-padding;
        }
    }

    tbody {

        tr {
            border-bottom: 1px solid #ccc;
        }

        th {
            padding: $_opc-table-padding;
        }
        td {
            padding: $_opc-table-padding;
        }
    }

    // Sortierung / tablesort
    &[data-op-type='tablesort'] {
        th {
            > .sort-header {
                display: grid;
                grid-template-columns: 1fr max-content;
                align-items: center;
                cursor: pointer;
            }
            &[data-op-sort-direction='desc'] {
                .sort-header-arrow {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px 6px 0 6px;
                    border-color: #000000 transparent transparent transparent;
                    margin-bottom: 3px;
                }
            }
            &[data-op-sort-direction='asc'] {
                .sort-header-arrow {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 6px 6px 6px;
                    border-color: transparent transparent #000000 transparent;
                    margin-bottom: 3px;
                }
            }
        }
    }

}