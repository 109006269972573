/*###############################################

@Title: Mixins
@Description:
This file sets the mixins for the core

###############################################*/



@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);
	@if $u1==$u2 and $u1==$u3 and $u1==$u4 {
		& {
			font-size: $min-font-size;
			@media screen and (min-width: $min-vw) {
				font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}
			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;
			}
		}
	}
}


/* *****************************************

SVG Inline Functions

***************************************** */

// Replace letters
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

// Encode symbols
@function url-encode($string, $color) {
    $map: (
        "%": "%25",
        "<": "%3C",
        ">": "%3E",
        //" ": "%20",
        "!": "%21",
        "*": "%2A",
        "'": "%27",
        '"': "%22",
        "(": "%28",
        ")": "%29",
        ";": "%3B",
        ":": "%3A",
        "@": "%40",
        "&": "%26",
        "=": "%3D",
        "+": "%2B",
        "$": "%24",
        ",": "%2C",
        "/": "%2F",
        "?": "%3F",
        "#": "%23",
        "[": "%5B",
        "]": "%5D"
    );

    $new: str-replace($string, 'height="32"', 'height="32" fill="#{$color}"');
    @each $search, $replace in $map {
        $new: str-replace($new, $search, $replace);
    }

    @return $new;
}

// Format the SVG as a URL
@function inline-svg($string, $color) {
    @return url('data:image/svg+xml,#{url-encode($string, $color)}');
}