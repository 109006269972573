/*###############################################

@Title: Typography
@Description:
This file contains the typography styles

###############################################*/

// font-size
$_opc-base-font-size:               1.4rem !default;

// fluid typography 
$_opc-fluid-typo:                   "true" !default;
$_opc-font-size-multiplyer:         1.2 !default;
$_opc-font-min-width:               320px !default;
$_opc-font-max-width:               1200px !default;

// font family and styles
$_opc-base-font-family:             "Arial" !default;
$_opc-base-font-weight:             400 !default;
$_opc-base-line-height:             1.4 !default;

$_opc-headline-font-family:         "Arial" !default;
$_opc-headline-font-weight:         400 !default;
$_opc-headline-line-height:         1.25 !default;

$_opc-lead-font-family:             "Arial" !default;
$_opc-lead-font-weight:             400 !default;
$_opc-lead-line-height:             1.25 !default;

// headlines
$_opc-font-size-h1:                 28px !default;
$_opc-font-size-h2:                 24px !default;
$_opc-font-size-h3:                 20px !default;
$_opc-font-size-h4:                 18px !default;
$_opc-font-size-h5:                 16px !default;
$_opc-font-size-h6:                 14px !default;

$_opc-font-size-h1-max:             $_opc-font-size-h1 * $_opc-font-size-multiplyer !default;
$_opc-font-size-h2-max:             $_opc-font-size-h2 * $_opc-font-size-multiplyer !default;
$_opc-font-size-h3-max:             $_opc-font-size-h3 * $_opc-font-size-multiplyer !default;
$_opc-font-size-h4-max:             $_opc-font-size-h4 * $_opc-font-size-multiplyer !default;
$_opc-font-size-h5-max:             $_opc-font-size-h5 * $_opc-font-size-multiplyer !default;
$_opc-font-size-h6-max:             $_opc-font-size-h6 * $_opc-font-size-multiplyer !default;

// lead and copy text
$_opc-font-size-lead:               $_opc-base-font-size * 1.4 !default;
$_opc-font-size-lead-max:           $_opc-font-size-lead * $_opc-font-size-multiplyer !default;

$_opc-font-size-small:              1.2rem !default;
$_opc-font-size-small-max:          $_opc-font-size-small * $_opc-font-size-multiplyer !default;

$_opc-font-size-copy:               $_opc-base-font-size !default;
$_opc-font-size-copy-max:           $_opc-font-size-copy * $_opc-font-size-multiplyer !default;

$_opc-font-size-s:                  1.2rem !default;
$_opc-font-size-m:                  1.4rem !default;
$_opc-font-size-l:                  1.6rem !default;
$_opc-font-size-xl:                 1.8rem !default;

h1,
.h1 {
    margin: 0 0 0.5em;
    font-family: $_opc-headline-font-family;
    font-weight: $_opc-headline-font-weight;
    line-height: $_opc-headline-line-height;
    font-size: $_opc-font-size-h1;
    
    @if $_opc-fluid-typo == 'true' {
        @include fluid-type($_opc-font-min-width, $_opc-font-max-width, $_opc-font-size-h1, $_opc-font-size-h1-max);
    }
}

h2,
.h2 {
    margin: 0 0 0.5em;
    font-family: $_opc-headline-font-family;
    font-weight: $_opc-headline-font-weight;
    line-height: $_opc-headline-line-height;
    font-size: $_opc-font-size-h2;
    
    @if $_opc-fluid-typo == 'true' {
        @include fluid-type($_opc-font-min-width, $_opc-font-max-width, $_opc-font-size-h2, $_opc-font-size-h2-max);
    }
}

h3,
.h3 {
    margin: 0 0 0.5em;
    font-family: $_opc-headline-font-family;
    font-weight: $_opc-headline-font-weight;
    line-height: $_opc-headline-line-height;
    font-size: $_opc-font-size-h3;
    
    @if $_opc-fluid-typo == 'true' {
        @include fluid-type($_opc-font-min-width, $_opc-font-max-width, $_opc-font-size-h3, $_opc-font-size-h3-max);
    }
}

h4,
.h4 {
    margin: 0 0 0.5em;
    font-family: $_opc-headline-font-family;
    font-weight: $_opc-headline-font-weight;
    line-height: $_opc-headline-line-height;
    font-size: $_opc-font-size-h4;
    
    @if $_opc-fluid-typo == 'true' {
        @include fluid-type($_opc-font-min-width, $_opc-font-max-width, $_opc-font-size-h4, $_opc-font-size-h4-max);
    }
}

h5,
.h5 {
    margin: 0 0 0.5em;
    font-family: $_opc-headline-font-family;
    font-weight: $_opc-headline-font-weight;
    line-height: $_opc-headline-line-height;
    font-size: $_opc-font-size-h5;
    
    @if $_opc-fluid-typo == 'true' {
        @include fluid-type($_opc-font-min-width, $_opc-font-max-width, $_opc-font-size-h5, $_opc-font-size-h5-max);
    }
}

h6,
.h6 {
    margin: 0 0 0.5em;
    font-family: $_opc-headline-font-family;
    font-weight: $_opc-headline-font-weight;
    line-height: $_opc-headline-line-height;
    font-size: $_opc-font-size-h6;
    
    @if $_opc-fluid-typo == 'true' {
        @include fluid-type($_opc-font-min-width, $_opc-font-max-width, $_opc-font-size-h6, $_opc-font-size-h6-max);
    }
}

.lead {
    margin: 0 0 0.5em;
    font-family: $_opc-lead-font-family;
    font-weight: $_opc-lead-font-weight;
    line-height: $_opc-lead-line-height;
    font-size: $_opc-font-size-lead;
    
    @if $_opc-fluid-typo == 'true' {
        @include fluid-type($_opc-font-min-width, $_opc-font-max-width, $_opc-font-size-lead, $_opc-font-size-lead-max);
    }
}

p,
.copy,
.text {
    margin: 0 0 0.5em;
    font-family: $_opc-base-font-family;
    font-weight: $_opc-base-font-weight;
    line-height: $_opc-base-line-height;
    font-size: $_opc-font-size-copy;
    
    @if $_opc-fluid-typo == 'true' {
        @include fluid-type($_opc-font-min-width, $_opc-font-max-width, $_opc-font-size-copy, $_opc-font-size-copy-max);
    }
}

small,
.small {
    margin: 0 0 0.5em;
    font-family: $_opc-base-font-family;
    font-weight: $_opc-base-font-weight;
    line-height: $_opc-base-line-height;
    font-size: $_opc-font-size-small;
    
    @if $_opc-fluid-typo == 'true' {
        @include fluid-type($_opc-font-min-width, $_opc-font-max-width, $_opc-font-size-small, $_opc-font-size-small-max);
    }
}

.opc-mini-label {
    font-size: 1.4rem;
    text-transform: uppercase;
    color: #000;
    opacity: 0.4;
    margin-bottom: 0.5em;
    display: block;
}