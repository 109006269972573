.opc-category-navigation{
    ul{
        li{
            flex-basis: calc(100% / 2);
            width: calc(100% / 2);
            max-width: calc(100% / 2);
            .page-start &{
                flex-basis: calc(100% / 3);
                width: calc(100% / 3);
                max-width: calc(100% / 3);
            }
            .category-item{
                .category-item-image{
                    background: none;
                    height: auto;
                    max-height: 36.5rem;
                    .page-start &{
                        max-height: 23.5rem;
                    }
                    &::before {
                        content: " ";
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: black;
                        background-image: url(/webportal/config/layout/themes/project/assets/img/add-circular-button.svg);
                        background-size: 70px 70px;
                        background-position: center;
                        background-repeat: no-repeat;
                        opacity: 0;
                        transition: opacity 0.4s ease-in-out;
                    }
                    img{
                        width: 100%;
                        //max-height: 36.5rem;
                    }
                }
                .category-item-title{
                    a{
                        height: auto;
                        color: $black!important;
                        font-size: 2rem!important;
                        margin: 1.5rem 0 .6rem;
                        .gray-section &{
                            color: $white!important;
                        }
                        .segmente-category &{
                            font-size: 2.4rem;
                        }
                    }
                }
                .category-item-description{
                    height: auto;
                    max-height: 8.4rem;
                    color: $black;
                    font-size: 1.6rem;
                    .gray-section &{
                        color: $white;
                    }
                }
                .category-item-more{
                    display: block;
                    margin: .3rem 0;
                    color: $brand-color-1;
                    font-weight: 700;
                    font-size: 1.6rem;
                    .gray-section &{
                        color: $white;
                    }
                }
            }
            &:hover{
                .category-item{
                    .category-item-image{
                        &::before {
                            opacity: .6;
                        }
                    }
                    .category-item-more{
                        .gray-section &{
                            color: $brand-color-1;
                        }
                    }
                }
            }
        }
    }
}