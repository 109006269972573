.opc-article-list {
    li{
        flex-basis: calc(100% / 2);
        max-width: calc(100% / 2);
        @include mq($min-width: map-get($breakpoints, s)) {
            flex-basis: calc(100% / 2);
            max-width: calc(100% / 2);
        }
        @include mq($min-width: map-get($breakpoints, m)) {
            flex-basis: calc(100% / 3);
            max-width: calc(100% / 3);
        }
        @include mq($min-width: map-get($breakpoints, normal)) {
            flex-basis: calc(100% / 4);
            max-width: calc(100% / 4);
        }
        padding: 1rem;
        cursor: pointer;
        .article-list-item {
            padding: 0;
            border: none;
            &:hover{
                border: none;
            }
            &.c-filter-items {
                cursor: pointer;
                .article-list-item-title{
                    span{
                        text-align: left;
                        line-height: 50px;
                        max-height: 50px;
                        overflow: hidden;
                        font-weight: normal;
                        position: relative;
                        font-size: 1.5rem;
                        font-weight: bold;
                        display: block;
                        padding: 0 1rem;
                    }
                }
            }
            a.article-list-item-image,
            span.article-list-item-image{
                //height: 10rem;
                position: relative;
                @include mq($min-width: map-get($breakpoints, s)) {
                    //height: 28rem;
                }
                position: relative;
                img {
                    width: 100%;
                    margin: 0;
                }
                &::before {
                    content: " ";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: black;
                    background-image: url(/webportal/config/layout/themes/project/assets/img/add-circular-button.svg);
                    background-size: 70px 70px;
                    background-position: center;
                    background-repeat: no-repeat;
                    opacity: 0;
                    transition: opacity 0.4s ease-in-out;
                }
            }
            .article-list-item-details {
                .opc-price {
                    min-height: 2.4rem;
                    margin-bottom: 0.5rem;
                }
                .article-list-item-title {
                    @extend .h4;
                    font-weight: 600;
                    margin-bottom: 0.5rem;
                    font-size: 1.6rem;
                    @include mq($min-width: map-get($breakpoints, s)) {
                        font-size: 2rem;
                    }
                    //height: 6.6rem;
                    overflow: hidden;
                    .article-list-item-number{
                        font-size: 1.2rem;
                        @include mq($min-width: map-get($breakpoints, s)) {
                            font-size: 1.6rem;
                        }
                        line-height: 2.2rem;
                        font-weight: 400;
                        font-style: normal;
                        margin-left: 1rem;
                        &::before{
                            content: "(";
                        }
                        &::after{
                            content: ")";
                        }
                    }
                }
                .article-list-item-spec{
                    font-size: 1.4rem;
                    @include mq($min-width: map-get($breakpoints, s)) {
                        font-size: 1.6rem;
                    }
                    .label{}
                    display: flex;
                    flex-direction: column;
                    >div.color{
                        >ul{
                            display: inline;
                            float: left;
                            margin: 0;
                            padding: 0;
                            >li{
                                float: left;
                                max-width: 100%;
                                list-style-type: none;
                                padding: 0 .6rem 0 0;
                                &::after{
                                    content: ',';
                                }
                                &:last-child{
                                    padding-right: 0;
                                    &::after{
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                }
                .article-list-item-description {
                    //height: 3.4rem;
                    overflow: hidden;
                    
                    p,
                    .is-product-msg {
                        height: 4.4rem;
                        font-size: 1.4rem;
                        overflow: hidden;
                        margin-bottom: 0;
                        padding: 0.5rem 0;
                        line-height: 1.3;
                        &:empty {
                            display: none;
                        }
                    }
                    .collection-description {
                        height:auto;
                    }
                    .is-product-msg {
                        background: #eee;
                        display: block;
                        padding: 0.5rem 1rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        border-radius: 1rem;
                        font-size: 1.2rem;
                        max-width: 60rem;
                    }
                }   
            }
            .article-list-item-actions {
                .details-button {
                    margin-bottom: 0.5rem;
                }
            }
        }
                
        &:hover {
            .article-list-item{
                a.article-list-item-image,
                span.article-list-item-image{
                    &::before {
                        opacity: .6;
                    }
                }
            }
        }
    }
    &[data-op-article-list-type="tile"] {
        .article-list-item {
            .opc-stock{
                position: absolute;
                &.has-loaded{
                    //top: 6.5rem;
                    @include mq($min-width: map-get($breakpoints, s)) {
                        //top: 14.5rem;
                    }
                    //left: .5rem;
                    bottom: 0;
                    left: 0;
                    padding: .5rem;
                    margin: 0;
                    background: rgba(0,0,0,.5);
                    color: #fff;
                }
            }
            a.article-list-item-image{
                height: initial;
                display: block;
                text-align: center;
                height: 100%;
            }
        }
    }
    &[data-op-article-list-type="list"] {
        >li{
            border-bottom: 1px solid $secondary-button-color;
        }
        .article-list-item {
            flex-grow: 1;
            border: none!important;
            //border: 1px solid transparent;
            // .opc-stock{
            //     position: static;
            //     font-size: 1.1rem;
            //     margin-bottom: 0;
            //     @include mq($min-width: map-get($breakpoints, xs)) {
            //         position: absolute;
            //         font-size: 1.6rem;
            //         margin-bottom: 1rem;
            //     }
            //     &.has-loaded{
            //         top: 0;
            //         right: 0;
            //         padding: .5rem;
            //     }
            // }
            .opc-stock{
                position: absolute;
                &.has-loaded{
                    bottom: 0;
                    left: 0;
                    padding: .5rem;
                    background: rgba(0,0,0,.5);
                    color: #fff;
                }
            }
            .article-list-item-details {
                .article-list-item-title {
                    font-size: 1.4rem;
                    @include mq($min-width: map-get($breakpoints, s)) {
                        font-size: 1.8rem;
                    }
                    font-weight: 600;
                    margin-bottom: 0.5rem;
                    height: auto;
                    overflow: hidden;
                }
                .article-list-item-spec{
                    flex-direction: row;
                    > div{
                        &::after{
                            content: ",";
                            margin-right: .5rem;
                        }
                        &:last-child{
                            &::after{
                                content: "";
                            }
                        }
                    } 
                }
            }
            &:hover{
                //border-bottom: 1px solid $secondary-button-color;
                border: none;
            }

        }
    }
}

.section-inner-aside{
    .opc-filter-set{
        display: flex;
        flex-direction: column;
        .filter-set-list{
            order: 3;
        }
        .filter-set-selection{
            order: 2;
            margin-bottom: 2rem;
        }
    }
}