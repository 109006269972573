/*###############################################

@Title: Selection List
@Description:
This file contains the selection list styles

###############################################*/

$_opc-selection-list-radio-width: 2.4rem !default;
$_opc-selection-list-radio-height: 2.4rem !default;
$_opc-selection-list-padding: 1.5rem 2rem 1.5rem 5rem !default;
$_opc-selection-list-border: 1px solid #eee !default;
$_opc-selection-list-icon: inline-svg($op-icon-check-svg, '#fff') !default;

.opc-selection-list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        margin-bottom: 0.5rem;
    }
    &[data-op-type="inline"] {
        li {
            display: inline-block;
            vertical-align: top;
            margin-right: 0.5rem;
        }
    }
    .opc-radio {
        margin-right: 0;
        display: block;
        label {
            padding: $_opc-selection-list-padding;
            display: block;
            border: $_opc-selection-list-border;
            &::before {
                top: 1.5rem;
                left: 1rem;
                height: $_opc-selection-list-radio-height;
                width: $_opc-selection-list-radio-width;
            } 
            &::after {
                top: 1.5rem;
                left: 1rem;
                color: #fff;
                height: $_opc-selection-list-radio-height;
                width: $_opc-selection-list-radio-width;
                background-image: $_opc-selection-list-icon;
            }
        }
        input:checked+label {
            background: #eee;
            &::before {
                background-color: #6eb25e;
                border-color: #6eb25e;
            }
            &::after {
                color: #fff;
            }
        }
    }
}