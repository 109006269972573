/*###############################################

@Title: Payment Info
@Description:
This file contains the payment info styles

###############################################*/

.opc-payment-infos-svg {
    list-style: none;
    margin: 0;
    padding: 0;
    @include clearfix();
    >li {
        float: left;
        margin-right: 4rem;
        &:last-child {
            margin-right: 0;
        }
        svg {
            color: white;
            font-size: 2em;
        }
    }
}