/*###############################################

@Title: Icon Box
@Description:
This file contains the iconbox style

###############################################*/

.opc-iconbox {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 60rem;
    width:100%;
    margin: 0 auto;
    text-align: center;
    padding: 4rem;
    
    svg {
        font-size: 8rem;
        align-self: center;
        margin-bottom: 2rem;
        width: 10rem;
        height: 10rem;
        border: 1px solid #999;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: #666;
        flex-shrink: 0;
        padding: 2rem;
    }
    
    b {
        margin-bottom: 1rem;
        font-size: 1.8rem;
    }
    p {
        @extend .lead; 
        margin-bottom: 1em;
        max-width: 100%;
    }
    .opc-button {
        display:inline-flex;
        margin: 0 auto;
        width: auto;
    }
    
    .opc-static-content {
        margin-bottom: 2rem;
    }

    &[data-op-style="success"] {
        svg  {
            border: 1px solid $success-color;
            color: $success-color;
        }
    }

    &[data-op-style="error"] {
        svg  {
            border: 1px solid $error-color;
            color: $error-color;
        }
    }
    
    
}