/*###############################################

@Title: Lists
@Description:
This file contains the list styles

###############################################*/

ul,
ol {
    
    li {
        
    }
}

dl {
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
        
    dt {
        font-weight: bold;
    }
    dd {
        margin: 0;
        padding: 0;
    }
}