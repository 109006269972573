/*###############################################

@Title: Basket Button
@Description:
This file contains the styling of the basket button

###############################################*/

$_opc-button-background-color:				#eee !default;
$_opc-button-background-color-hover:		darken($_opc-button-background-color, 10%) !default;

.opc-basket-button {
    .basket-button-dim-variants {
        @extend .opc-select;
        margin-right: 0.4rem;
    }
    .basket-button-dim-1,
    .basket-button-dim-2,
    .basket-button-dim-3 {
        display: inline-block;
        vertical-align: middle;
        margin-left: 1rem;
        &:first-of-type {
            margin-left: 0;
        }
        display: inline-block;
        vertical-align: middle;
        input {
            height: map-get($heights, 'normal');
            padding: 0 0.5rem;
            width: 8rem;
            width: 3rem;
            height: map-get($heights, 'normal');
            padding: 0;
            text-align: right;
            border-color: transparent;
            border-bottom-color: #ccc;
            text-align: center;
            font-weight: bold;
            margin-right: 0.5rem;
        }
        &.is-inactive {
            display: none !important;
        }
    }
    button {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        svg {
            font-size: 1.4em;
            margin-right: 0.5rem;
            flex-shrink: 0;
        }
    }
    &[data-op-type="multi-dim"] {
        display: inline-block;
    }
    &[data-op-type="single-dim"] {
        display: inline-block;
        display: inline-flex;
        .basket-button-dim-1 {
            display: inline-block;
            vertical-align: middle;
        }
    }
    &[data-op-type="nodim"] {
        .basket-button-dim-1,
        .basket-button-dim-2,
        .basket-button-dim-3 {
            display: none;
        }
    }
    &[data-op-type="icon"] {
        display: inline-block;
        .basket-button-dim-1,
        .basket-button-dim-2,
        .basket-button-dim-3 {
            display: inline-block;
            vertical-align: middle;
            input {
                width: 3rem;
                height: map-get($heights, 'normal');
                padding: 0;
                text-align: right;
                border-color: transparent;
                border-bottom-color: #ccc;
                text-align: center;
                font-weight: bold;
                margin-right: 0.5rem;
            }
            display: none;
        }
        button {
            svg {
                font-size: 1.4em;
            }
            span {
                display: none;
            }
        }
    }
    &[data-op-layout="touch"] {
        display: flex;
        flex-wrap: wrap;
        .basket-button-dim-1,
        .basket-button-dim-2,
        .basket-button-dim-3 {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-left: 0;
            label {
                display: block;
                width: 100%;
            }
            .opc-button {
                height: 3rem;
                width: 4rem;
                padding: 0;
                font-weight: bold;
                text-align: center;
                background: $_opc-button-background-color;
                border: 1px solid $_opc-button-background-color;

				&[data-op-qtychange="inc"], &[data-op-qtychange="dec"] {
					opacity: 0.8;
					 &:hover, &:focus {
						 opacity: 1;
						 background: $_opc-button-background-color-hover;
    				}
					&:focus {
    			    outline: none;
    				}
				}
            }
            .basket-button-quantity {
                height: 3rem;
                margin-right: 0;
                width: 6rem;
                border: 1px solid $_opc-button-background-color;
            }


        }
    }
}