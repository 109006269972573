/*###############################################

@Title: Helper
@Description:
This file set the grid the correct way

###############################################*/

@mixin clearfix()  {
	&::after {
		content: '';
		width: 100%;
		display: block;
		clear: both;
	}
}
.clearfix {
	width: 100%;
	display: block;
	clear: both;
}


%mw {
	width: 100%;
	padding-left: $mw-padding / 3;
	padding-right: $mw-padding / 3;
	
	@include mq($min-width: map-get($breakpoints, m)) {
		padding-left: $mw-padding / 2;
		padding-right: $mw-padding / 2;
	}
	@include mq($min-width: map-get($breakpoints, xl)) {
		padding-left: $mw-padding;
		padding-right: $mw-padding;
	}
	 
}
%mw-inner {
	max-width: $mw;
	margin-left: auto;
	margin-right: auto;
} 




.is-visuallyhidden {
	position: absolute !important;
	clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px);
	padding:0 !important;
	border:0 !important;
	height: 1px !important; 
	width: 1px !important; 
	overflow: hidden;
}


.spacer {
	margin-bottom: map-get($spacing, small);

	&[data-op-spacer="small"] {
		margin-bottom: map-get($spacing, small);	
	}
	&[data-op-spacer="medium"] {
		margin-bottom: map-get($spacing, medium);	
	}
	&[data-op-spacer="large"] {
		margin-bottom: map-get($spacing, large);	
	}

}

.loader {
  border: 8px solid #ccc;
  border-top: 8px solid $brand-color-1;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.fetch-loader-frame {
	display: grid;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    position: absolute;
	opacity: 0.6;
	z-index: 99999;
	cursor: progress;
}