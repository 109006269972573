/*###############################################

@Title: Selection (Dropdown)
@Description:
This file contains the selection styles for product change

###############################################*/

.opc-product-article-selection {
    // basic ul styling
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li a {
            margin: -2rem;
            padding: 2rem;
            display: block;
        }
    } // basic image styling
    img {
        max-width: 100%;
        max-height: 100%;
    } // disabled styling for item
    .is-disabled {
        cursor: default !important;
        opacity: 0.4;
        pointer-events: none;
        a {
            cursor: default;
        }
    }
    .product-article-selection-label {
        font-weight: bold;
        margin-right: 1rem;
        margin-bottom: 0.5rem;
    }
    .product-article-selection-options {
        .option-item {
            display: inline-flex;
            padding: 2rem;
            border: 1px solid #ccc;
            &:hover {
                border-color: #666;
            }
            &.is-selected {
                border-color: $brand-color-1;
            }
        }
    } // specific styles for image type
    &[data-op-type="image"] {
        .option-item {
            padding: 1rem;
            height: 10rem;
        }
    } // layout options
    &[data-op-layout="inline"] {
        display: flex;
        align-items: flex-start;
        margin-right: 2rem;
        .option-item {}
    }
    &[data-op-layout="list"] {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .product-article-selection-label {
            display: block;
            width: 100%;
        }
        .product-article-selection-options {
            width: 100%;
            display: flex;
            .option-item {
                display: block;
                margin-bottom: 0.5rem;
                margin-right: 1rem;
            }
        }
    }
    &[data-op-layout="select"] {
        //display: inline-flex;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        .product-article-selection-label {}
        .product-article-selection-options {
            width: 100%;
            position: relative;
            .option-item-list-selection {
                border: 1px solid #ccc;
                padding: 2rem;
                width: 100%;
                cursor: pointer;
                background-image: inline-svg($op-icon-angle-down-svg, '#000');
                background-position: calc(100% - 1rem) center;
                background-size: 2rem;
                background-repeat: no-repeat;
            }
            .option-item-list {
                display: none;
                z-index: 10;
                background: #fff;
                border: 1px solid #ccc;
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                max-height: 12rem;
                overflow: auto;

                .option-item {
                    display: block;
                    border: none;
                    padding: 1rem 2rem;
                    border-bottom: 1px solid #ccc;
                    cursor: pointer;
                    &:hover {
                        background: #efefef;
                    }
                    &:last-of-type {
                        border-bottom: none;
                    }
                    &.is-selected {
                        background: $brand-color-1;
                        color: #fff;
                    }
                    a {
                        color: inherit;
                    }
                }
            }
            &.is-open {
                .option-item-list {
                    display: block;
                }
            }
        }
    }
}