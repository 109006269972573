/*###############################################

@Title: Content
@Description:


###############################################*/

// namespace vars
$_header-bg:                                        #fff;                             // header background color
$_header-offcanvas-menu-trigger-color:              #222;                             // color of the hamburger menu icon
$_header-padding:                                   2rem;                             // top and bottom padding of the header
$_header-offcanvas-menu-margin:                     1rem;                             // space between hamburger menu and brand
$_header-spacing:                                   4rem;                             // Spacing between the header sections eg. brand, search, actions


// display config
$always-show-offcanvas-menu-trigger:                'false';                          // define if you want to show the hamburger menu just on mobile or always    
$show-offcanvas-menu-trigger:                       map-get($breakpoints, l);    // set breakpoint to define when the hamburger will shown
 


// header layout styles
.header-wrapper { 
	@extend %mw;
	background: $_header-bg;
    box-shadow: 0 0 3px #ccc;
    position: sticky;
    top: 0;
    z-index: 200;   
    
	@include mq($min-width: map-get($breakpoints, l)) {
		box-shadow: none;
		position: relative;
	}
	@include mq($min-width: map-get($breakpoints, xl)) {
		
	}
	
	 
	.header-inner {
		@extend %mw-inner;
		//@include clearfix();
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
  
		height: 6rem;

		@include mq($min-width: map-get($breakpoints, s)) {
			height: 7rem;
		}
		.opc-search {
			position: relative;
			background: none;
			visibility: visible;
			opacity: 1;
			z-index: 20000;
			flex-grow: 0;
			flex-shrink: 1;
			order: 4;
			display: none;
			flex-grow: 1;
			width: auto;
			margin-left: 3rem;
			@include mq($min-width: map-get($breakpoints, l)) {
				display: block;
			}
		}
	}
	
	
	// offcanvas menu
	
.offcanvas-menu-trigger {
	margin-right: $_header-offcanvas-menu-margin;
	border: none;
	padding: 0.5rem;
	background: none;
	opacity: 1;
	cursor: pointer;
	&:hover {
		opacity: 1;
	}
	span {
		@include burger(40px, 5px, 5px, $brand-color-1);
	}
	&.is-active {
		span { 
			@include burger-to-cross;
		}
	}
	@if $always-show-offcanvas-menu-trigger !='true' {
		display: none;
		@include mq($max-width: #{$show-offcanvas-menu-trigger}) {
			display: block;
		}
	}
	
	order: 1;

}


	// logo 
	.brand {
		margin-right: $_header-spacing / 4;
		order: 2;
		//flex-grow: 1;
		padding: 0 0 0.5rem;
		width: 22rem;
		min-width: 12rem;
		max-width: 22rem;
		margin-left:0;

		a {
			display: block;
		}

		img {
			display: block;
			// width: 220px;
			max-width: 100%;
			max-height: 9rem;
		}
		@include mq($min-width: map-get($breakpoints, l)) {
			margin-left:-1.5vw;
		}
		
		@include mq($min-width: map-get($breakpoints, xl)) {
			margin-left: -10vw;
		}
		&.custshop {
			margin-left: 0;
		}
		
		
	}


	// header search 
	.header-search { 
		flex: 1;
		display: flex;
		margin-right: $_header-spacing / 2;
		width: 100%;
		min-width: 100%;
		order: 5;
		position: absolute;
		//margin-top: $_header-spacing / 4;
				 
		
		&::before {
			content: '';
			display: block;
		}
		
		@include mq($min-width: map-get($breakpoints, l)) {
			margin-right: $_header-spacing / 2;
			margin-top: 0;
			min-width: 0;
			order: 2;	
			position: relative;		
		}
		@include mq($min-width: map-get($breakpoints, xl)) {
			margin-right: $_header-spacing;
		}
		
		input {
			height: 3rem;
			width: 100%;
		}

		
	}

	.header-navigation {
		order: 3;
		margin-left:auto;
		display: none;

		@include mq($min-width: map-get($breakpoints, l)) {
			display: block;
		}
		&.align-left {
			margin-right: auto;
			margin-left: 0;
		}
	}

	// header actions
	.header-actions {
		display: flex;
		order: 4;
		flex-grow: 1;
		justify-content: flex-end;

		@include mq($min-width: map-get($breakpoints, l)) {
			flex-grow:0;
			 
		}
	
		> .opc-favorite-info,
		> .opc-basket-info,
		> .search-toggle,
		> .account-link {
			margin: 0;
			display: flex;
			

			> .opc-button {
				margin: 0;
				padding: 0;
				width: 4rem;
				height: 4rem;

				color: #000;
				&:hover {
					color: lighten(#000, 40%);
				}
				
				> svg {
					font-size: 3.6rem;
					margin: 0;  
				}

				@include mq($min-width: map-get($breakpoints, s)) {
					width: 5rem;
					 
				}

			} 

			> a {
				color: #000;
				&:hover {
					color: lighten(#000, 40%);
				}
			}

			
		}

		> .account-link {
			
			@include mq($min-width: map-get($breakpoints, l)) {
				display: none;
			}
			.account-link-login-indicator {
				width: 1.2rem;
				height: 1.2rem;
				background: $success-color;
				border-radius: 50%;
				position: absolute;
				bottom: 0.4rem;
				right: 0.6rem;
			}
		}

		> .opc-favorite-info {
			display: none;
			@include mq($min-width: map-get($breakpoints, l)) {
				display: flex;
			}
		}

		


		.opc-basket-info {
			margin-left: -5px;
			display: block;
			@include mq($min-width: map-get($breakpoints, l)) {
				display: none;
			}
			>.opc-button{
				width: 6rem;
				.basket-info-count{
					margin-left: -5px;
				}
			}
			svg {
				font-size: 4rem !important; 
			}
		}
    	.search-toggle {
			cursor: pointer;
			//margin-right: 0.5rem;
			width: 4rem;
			height: 4rem;  
			padding-left: 0;
			padding-right: 0;
			background: transparent;
			
			svg {
				font-size: 3.2rem;
				margin: 0;
			}
		
			@include mq($min-width: map-get($breakpoints, s)) {
				width: 5rem;	 
			}
			@include mq($min-width: map-get($breakpoints, l)) {
				
				&.search-toggle {
					display: none;
				}
			}
			
			
		}
		
	}
}


.header-wrapper{
	.offcanvas-menu-trigger{
		order: 3;
	}
	.brand{
		order: 1;
	}
	.header-actions{
		order: 2;
	}
}
@include mq($min-width: map-get($breakpoints, l)) {
	.header-wrapper{
		.offcanvas-menu-trigger{
			order: 1;
		}
		.brand{
			order: 2;
		}
		.header-actions{
			order: 3;
		}
	}
}