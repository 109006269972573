/*###############################################

@Title: Extended Head
@Description:
This file contains the extended head styles

###############################################*/

$_opc-extended-head-bg : #f8f8f8 !default;
$_opc-extended-head-padding : 4rem 2rem !default;
.opc-extended-head {
    background: $_opc-extended-head-bg;
    padding: $_opc-extended-head-padding;
    margin-bottom: 2rem;
    h1 {
        margin-bottom: 0.8em !important;
    }
    p {}
}