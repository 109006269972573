$cms-topbar-color: #333 !default;
$cms-topbar-bg-color: #fbfbfb !default;
$cms-topbar-border: 1px solid #ccc !default;
$cms-search-border-color: #CCC !default;
$cms-search-bg-color: #FFF !default;
$cms-search-color: #000 !default;
$cms-topbar-button-bg-color: #333 !default;

.eshop-toolbar {
    display: flex;
    width: 100%;
    color: $cms-topbar-color;
    background: $cms-topbar-bg-color;
    border-bottom: $cms-topbar-border;
    z-index: 999;
	display: none;
	@include mq($min-width: map-get($breakpoints, l)) {
		display: flex;
	}

    .toolbar-title {
        display: flex;
        align-items: center;
        font-size: 1.8rem;
        padding: 1rem;
    }
    .admin-panel{
        height: 1005;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .toolbar-group{
            padding: 0 2rem;
            span{
                a{
                    color: $cms-topbar-color;
                }
            }
        }
    }
    .cms-topbar{
        width: 50%;
        margin-left: auto;
        display: flex;
        color: $cms-topbar-color;
        background: $cms-topbar-bg-color;
        // border-bottom: $cms-topbar-border;
        .cms-actions {
            margin-left: auto;
            display: flex;
            align-items: center;
    
            .cms-edit-toggle {
                display: flex;
                align-items: center;
                padding-left: 1rem;
                padding-right: 1rem;
    
                label {
                    margin-bottom: 0;
    
                    input:checked+.slider {
                        background-color:$success-color;
                    }
                }
                > span {
                    margin-right: 1rem;
                }
            }
            .opc-button {
                border-radius: 0;
                background-color: $cms-topbar-button-bg-color;
                border-color: darken($cms-topbar-button-bg-color, 5%);
            }
    
            select {
                border-color: $cms-search-border-color;
                background-color: $cms-search-bg-color;
                color: $cms-search-color;
            }
        }
    }
}

#opc-cms-fileupload{
    display: none;
}

[data-op-asyn] {
    height: 150px;
    background-color: #EFEFEF;
    animation-name: loadingColor;
    animation-duration: 2s;
    animation-iteration-count: infinite;

    &.finished {
        animation: none;
        background-color: transparent;
        height: auto;
    }

    > span {
        display: none;
    }
}

@keyframes loadingColor {
    0% {
        background-color: #EFEFEF;
    }
    50% {
        background-color: darken(#EFEFEF, 10%);
    }
    100% {
        background-color: #EFEFEF;
    }
}

[data-opc-cms="toggle"] {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 1rem;

    > span {
        margin-left: 1rem;
    }
}