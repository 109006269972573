/*###############################################

@Title: Page Basket
@Description:


###############################################*/

.basket-list {
    //padding: 2rem 0;
    .basket-list-head {
        //padding: 2rem;
        display: none;
        border: 1px solid transparent;
        @include mq($min-width: map-get($breakpoints, m)) {
            display: flex;
        }
        >div {
            border-right: none;
            font-weight: bold;
        }
    }
    .basket-list-body {
        list-style: none;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        .basket-list-item {
            display: flex;
            flex-direction: column;
            border: 1px solid #ccc;
            margin-bottom: 1rem;
            flex-wrap: wrap;
            @include mq($min-width: map-get($breakpoints, m)) {
                //display: flex;
                flex-direction: row;
                margin-bottom: -1px;
                margin-bottom: 1rem;
            }
            .opc-button[data-op-btnaction="delete"] {
                padding: 0;
                background: transparent;
                color: crimson;
                padding: 0;
                height: auto;
                margin-bottom: 1rem;
                font-size: 1.2rem;
                svg {
                    display: none;
                }
                &.is-progress {
                    color: #ccc;
                }
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        .basket-list-item-promocode {
            border: 1px solid #ccc;
            padding: 2rem;
            margin-bottom: 1rem;
            h3,
            p,
            .opc-form-field {
                max-width: 50rem;
            }
        }
    }
    .basket-list-footer {
        background: #eee;
        padding: 2rem 1rem;
        display: flex; //justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        margin-bottom: 2rem;
        .basket-list-summary-line {
            margin-bottom: 1rem;
            span {
                display: inline-block;
                width: 14rem;
                text-align: right;
            }
        }
    }
    .basket-bottom-line {
        display: flex;
        justify-content: flex-end;
        >a.opc-button{
            &-inactive{
                @extend .opc-button;
                pointer-events: none;
                opacity: .5;
            }
        }
    }
    .basket-list-item-description,
    .basket-list-item-options,
    .basket-list-item-price,
    .basket-list-item-quantity,
    .basket-list-item-total {
        padding: 0.5rem 1rem;
        @include mq($min-width: map-get($breakpoints, s)) {
            padding: 1rem;
        }
    }
    .basket-list-item-description {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-bottom: 0;
        @include mq($min-width: map-get($breakpoints, m)) {
            width: 20%;
            flex-grow: 1; //border-right: 1px solid #ccc;    
            flex-wrap: nowrap;
            padding-bottom: 1rem;
        }
        .item-description-image {
            width: 8rem;
            padding: 1rem 1rem 0 0;
            flex-shrink: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: flex-start;
            img {
                width: auto;
                max-width: 6rem;
                max-height: inherit;
                max-height: 8rem;
            }
            @include mq($min-width: map-get($breakpoints, s)) {
                width: 10rem;
                img {
                    align-items: center; //max-width: 100%;
                    //width: auto;
                    max-width: 7rem;
                    height: inherit;
                    max-height: 7rem;
                }
            }
        }
        .item-description-data {
            padding: 1rem 0;
            width: calc(100% - 8rem);
            display: flex;
            flex-direction: column;
            @include mq($min-width: map-get($breakpoints, s)) {
                width: calc(100% - 14rem);
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                padding: 0;
            }
            h3 {
                margin-bottom: 0.4em;
                font-size: 1.4rem;
                font-weight: bold;
                a {
                    color: #000;
                    &:hover {
                        color: lighten(#000, 20%);
                    }
                }
            }
            .basket-list-item-spec{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                > div{
                    &::after{
                        content: ",";
                        margin-right: .5rem;
                    }
                    &:last-child{
                        &::after{
                            content: "";
                        }
                    }
                }
                > div.color{
                    >ul{
                        display: inline;
                        float: left;
                        margin: 0;
                        padding: 0;
                        >li{
                            list-style-type: none;
                            float: left;
                            padding: 0 .6rem 0 0;
                            &::after{
                                content: ',';
                            }
                            // &:last-child{
                            //     &::after{
                            //         display: none;
                            //     }
                            // }
                        }
                    }
                    &::after{
                        display: none;
                    }
                }
            }
            .postext{
                margin-top: auto;
                > label{
                    display: block;
                }
            }
        }
        .item-description-article-nr {
            font-weight: 400;
            white-space: nowrap;
            &::before{
                content: "(";
            }
            &::after{
                content: ")";
            }
        }
        .item-description-actions {
            button {
                //text-decoration: underline;
                padding: 0;
                height: auto;
                opacity: 0.6;
                &:hover {
                    opacity: 1;
                    color: $error-color;
                }
            }
        }
    }
    .basket-list-item-options {
        .unit{
            display: block;
            font-size: 1.4rem;
            font-weight: 700;
            margin-bottom: .5rem;
        }
        .opc-form-field-group {
            margin-bottom: 1rem;
        }
        .opc-basket-button {
            margin-bottom: 1rem;
        }
        .opc-stock {
            margin-bottom: 1rem;
        }
        //margin-left: 8rem;
        padding-top: 0;
        @include mq($min-width: map-get($breakpoints, s)) {
            margin-left: 14rem;
        }
        @include mq($min-width: map-get($breakpoints, m)) {
            width: 18rem;
            margin-left: 0;
            padding-top: 1rem; //border-right: 1px solid #ccc;
        }
    }
    .basket-list-item-quantity {
        //width: 100%;
        text-align: left;
        //margin-left: 8rem;
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        justify-content: space-between;
        @include mq($min-width: map-get($breakpoints, s)) {
            margin-left: 14rem;
            padding-top: 0;
            padding-bottom: 0.5rem;
        }
        @include mq($min-width: map-get($breakpoints, m)) {
            width: 10rem;
            text-align: right;
            margin-left: 0; //border-right: 1px solid #ccc;
            padding-top: 1rem;
            padding-bottom: 1rem;
            display: block;
        }
    }
    .basket-list-item-price {
        //width: 100%;
        text-align: left;
        //margin-left: 8rem;
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        justify-content: space-between;
        @include mq($min-width: map-get($breakpoints, s)) {
            margin-left: 14rem;
            padding-top: 0;
            padding-bottom: 0.5rem;
        }
        @include mq($min-width: map-get($breakpoints, m)) {
            width: 12rem;
            text-align: right;
            margin-left: 0; //border-right: 1px solid #ccc;
            padding-top: 1rem;
            padding-bottom: 1rem;
            display: block;
        }
        .price-brutto{
            &::before{
                content: "(";
            }
            &::after{
                content: ")";
            }
        }
    }
    .basket-list-item-price {
        //width: 100%;
        text-align: left;
        //margin-left: 8rem;
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        justify-content: space-between;
        @include mq($min-width: map-get($breakpoints, s)) {
            margin-left: 14rem;
            padding-top: 0;
            padding-bottom: 0.5rem;
        }
        @include mq($min-width: map-get($breakpoints, m)) {
            width: 12rem;
            text-align: right;
            margin-left: 0; //border-right: 1px solid #ccc;
            padding-top: 1rem;
            padding-bottom: 1rem;
            display: block;
        }
        .price-brutto{
            display: block;
            font-size: 1.2rem;
        }
    }
    .basket-list-item-total {
        //width: 100%;
        text-align: left;
        //margin-left: 8rem;
        padding-top: 0;
        padding-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        @include mq($min-width: map-get($breakpoints, s)) {
            margin-left: 14rem;
            padding-top: 0;
            padding-bottom: 1rem;
        }
        @include mq($min-width: map-get($breakpoints, m)) {
            width: 14rem;
            text-align: right; //border-right: 1px solid #ccc;
            font-weight: bold;
            margin-left: 0;
            padding-top: 1rem;
            padding-bottom: 1rem;
            display: block;
        }
        .discount-badges{
            display: none;
            margin-top: .5rem;
            @include mq($min-width: map-get($breakpoints, s)) {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
            >span{
                margin-bottom: .2rem;
                display: flex;
                align-items: center;
                background: #eee;
                justify-content: space-between;
                max-width: 11rem;
                .text{
                    font-size: 1.2rem;
                    padding: .3rem;
                    background: #434343;
                    color: #fff;
                    margin: 0;
                    width: 3.4rem;
                    text-align: center;
                }
                .value{
                    font-size: 1.2rem;
                    padding: .3rem .6rem;
                    background: #eee;
                    flex-shrink: 1;
                }
            }
        }
    }
    .basket-list-item-discount{
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        width: 100%;
        @include mq($min-width: map-get($breakpoints, s)) {
            display: none;
        }
        >span{
            padding: .5rem;
            display: flex;
            align-items: center;
            .text{
                font-size: 1.4rem;
                padding: .3rem;
                background: #434343;
                color: #fff;
                margin: 0;
            }
            .value{
                font-size: 1.4rem;
                padding: .3rem 1rem;
                background: #eee;
            }
        }
    }
    .responsive-label {
        display: inline-block;
        margin-right: 0.4rem;
        font-weight: bold;
        min-width: 40%;
        @include mq($min-width: map-get($breakpoints, m)) {
            width: 14rem;
            display: none; //border-right: 1px solid #ccc;
        }
    }
}

.basket-quickadd{
    margin-bottom: 1rem;
    border-bottom: 8px solid $brand-color-1;
    .custsort-list-quicksearch{
        position: relative;
        [name="field-artnumber"]{
            border: 1px solid $gray;
            height: 4rem;
            line-height: 4rem;
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 50%!important;
                border: 2px solid $gray;
                height: 6rem;
                line-height: 6rem;
                font-size: 2rem;
            }
            margin: auto;
            display: block;
            padding: 2rem;
            border-radius: 3rem;
            background: #fff;
            &::placeholder {
                color: rgba($gray,.4);
            }
        }
        .quickadd-selectbox{
            //position: absolute;
            z-index: 10;
            max-height: 250px;
            overflow-y: auto;
            //border: 1px solid #EBEBEB;
            background: #fff;
            top: 4rem;
            left: 0;
            #quicksearch-result{
                margin: 0;
                li.list-item{
                    border-bottom: 1px solid #ccc;
                    .addButton{
                        display: none;
                    }
                    &.option_box_hover{
                        .article-list-item{
                            border-color: rgba($gray, .2);
                        }
                    }
                    .article-list-item{
                        border: none;
                        .article-list-item-image{
                            height: 60px;
                        }
                    }
                }
            }
        }
    }
    .item-description-article-nr {
        font-weight: 400;
        &::before{
            content: "(";
        }
        &::after{
            content: ")";
        }
    }
    .basket-list-item-spec{
        display: flex;
        flex-direction: row;
        font-weight: 400;
        font-size: 1.4rem;
        > div{
            &::after{
                content: ",";
                margin-right: .5rem;
            }
            &:last-child{
                &::after{
                    content: "";
                }
            }
        } 
    }
}

