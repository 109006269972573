a {
    &.overlay {
        display: inline-block;

        img {
            margin: 0;
        }

        &::before {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 5px;
            background-color: black;
            background-image: url(/webportal/config/layout/themes/project/assets/img/add-circular-button.svg);
            background-size: 70px 70px;
            background-position: center;
            background-repeat: no-repeat;
            opacity: 0;
            transition: opacity 0.4s ease-in-out;
        }

        &:hover {
            &::before {
                opacity: .6;
            }
        }
    }
}

[data-editmode="true"] {
    a {
        &.overlay {
            &::before {
                display: none;
            }
        }
    }
}