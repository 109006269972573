.opc-basket-button {
    .basket-button-dim-variants{
        margin-right: .5rem;
    }
    .basket-button-dim-2{
        margin-right: .5rem;
    }
    .basket-button-dim-2{
        margin: 0;
        margin-right: .5rem;
    }
    .basket-button-dim-3{
        margin: 0;
    }
}