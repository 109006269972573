/*
@Title: Project File
@Description:
This file defines which sass files are necessary for the project.
There are 4 Import Types: 1. Node Modules, 2. Theme Config File, 3. Core Files, 4. Theme Files.
Please keep in mind, that the order is IMPORTANT and cannot be changed!!!!!!
*/

/*
 Import external resources
 Here you can insert all external resources (node modules, vendor stuff, etc.) for the project which are using sass or css
 ---------------------------------------------------- */
 @import "./webportal/config/layout/themes/tmpCdnSource/vendor/normalize.css/normalize";
 @import "./webportal/config/layout/themes/tmpCdnSource/vendor/susy/sass/susy";
 @import "./webportal/config/layout/themes/tmpCdnSource/vendor/sass-mediaqueries/media-queries";
 @import "./webportal/config/layout/themes/tmpCdnSource/style/mixins";

/*
  Import Theme Config File
  Here you have to link to the config file of the theme you wanna use.
  ---------------------------------------------------- */
 @import "themes/project/scss/config";

/*
 Import external resources
 Here you can insert all external resources (node modules, vendor stuff, etc.) for the project which are using sass or css
 ---------------------------------------------------- */
 @import "./webportal/config/layout/themes/tmpCdnSource/lib/notifications/opacc.lib.notifications";
 @import "./webportal/config/layout/themes/tmpCdnSource/lib/daterangepicker/opacc.lib.daterangepicker";
 @import "./webportal/config/layout/themes/tmpCdnSource/lib/tooltip/opacc.lib.tooltip";
 @import "./webportal/config/layout/themes/tmpCdnSource/lib/autocomplete/opacc.lib.autocompleter";

 /* 

  Import the Core
  Here we import the core. Normally you should not touch this...
  ---------------------------------------------------- */
 @import "./webportal/config/layout/themes/tmpCdnSource/style/core";

 /*
  Import Theme Styles
  Here you have to link to the theme file.
  ---------------------------------------------------- */
 @import "themes/project/scss/theme";


 .cfilter-wrapper.zoomOutDown {
    transform: translate3d(0,100%,0) scale(0.2);
    height: 0 !important;
    opacity: 0;
 }

 .cfilter-wrapper {
    transition: transform 0.5s ease-out , opacity 0.25s ease-out, height 0.3s ease-out;
    transform-origin: left top;
 }

.opc-extended-head-white{
   background: $white;
   margin-bottom: 4rem;
}

// Fix
.opc-item-list .list-item{
   &:hover{
      padding: 0;
   }
   form{
      align-items: center;
   }
   .opc-form-field{
      padding: .3rem;
   }
}

.hidden{
   display: none;
}

.opc-manager-cookie{
   .opc-cookie-message{
      background: rgba(#333,.9)
   }
}

.opc-segment-navigation {
   a.no-click {
      color: grey;
      pointer-events: none;
   }
}