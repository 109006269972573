/*###############################################

@Title: Search
@Description:
This file contains the search styles (oldscl)

###############################################*/

.opc-search {
    position: relative;
    width: 100%;

    &.is-open {
		visibility: visible;
		opacity: 1;
		z-index: 20000;
	}

	.search-toggle {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 3;
    	display: none;
    }

    .opc-search-bg {}
    .opc-search-content {
        .opc-search-query {
            border: 1px solid #ccc;
            display: flex;
            padding: 0.5rem;
            .search-query {
                border: none;
                height: 3rem;
                &:focus {
                    outline: none;
                }
            }
			.search-filter {
				border: none;
				border-left: 1px solid #ccc;
				border-right: 1px solid #ccc;
				padding: 0 1rem;
				height: 3rem;
				&:focus {
					outline: none;
				}
			}
			.search-btn {
				background: none;
				border: none;
				height: 3rem;
				width: 4rem;
				flex-shrink: 0;
				color: $brand-color-1;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 2rem;
			}
		}
        .opc-search-results {
            display: none;
            position: absolute;
            top: 3rem;
            background: #fff;
            width: 100%;
            padding: 2rem;
            border: 1px solid #ccc;
            margin-top: -1px;
            min-height: 5rem;
            &::before {
                content: '';
                width: 3rem;
                height: 3rem;
                background: transparent;
                display: block;
                border-radius: 50%;
                margin: 0 auto;
                position: absolute;
                left: 50%;
                top: 1rem;
                margin-left: -1.5rem;
                border: 2px solid transparent;
                border-top: 2px solid $brand-color-1;
                animation-name: rotater;
                animation-duration: 2s;
                animation-iteration-count: infinite;
            }
            @keyframes rotater {
                50% {
                    transform: rotate(400deg);
                }
            }
        }
    }
    input {
        width: 100%;
    }



    // fullscreen search
    &[data-op-type="fullscreen"] {

        position: fixed;
        z-index: 100;
        background: rgba(#fff, 0.98);
        width: 100%;
        height: 100%;
        visibility: hidden;
        opacity: 0;
        z-index: -10;
        pointer-events: none;

        .search-open & {
            visibility: visible;
            z-index: 1000;
            opacity: 1;
            pointer-events: all;
        }

        .search-head {
            height: 12rem;
            @extend %mw;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            box-shadow: 0 0 6px #ccc;
            z-index: 20000;
            position: relative;
            padding: 0 2rem;

            button {
                color: #000;
                margin-right: 0;
                margin-right: -1rem;
                cursor: pointer;
                width: 4rem;
                height: 4rem;
                padding-left: 0;
                padding-right: 0;
                background: transparent;

                svg {
                    font-size: 3.2rem;
                    margin: 0;
                    color: #000;
                }
            }
        }

        .search-query {
            height: 6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            input {
                height: 5rem;
                border: 1px solid #ccc;
                width: 100%;
                padding: 0 1rem;
            }
        }

        .search-body {
            position: absolute;
            top: 12rem;
            bottom: 0;
            left: 0;
            right: 0;
            overflow-y: auto;
            padding: 2rem;

            .opc-directsearch-suggest {
                background: none;

                .directsearch-suggest-row {
                    margin-left: -1rem;
                    margin-right: -1rem;
                }
                .directsearch-suggest-column {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
        }

        .opc-search-results {
            margin-bottom: 4rem;
        }


        .search-slider {
            display: flex;
            flex-wrap: nowrap;
            max-width: 100%;
            overflow-x: auto;
            margin-bottom: 2rem;

            .search-slider-item {
                margin-right: $_opc-grid-gutter-width;
                width: 12rem;
                flex-shrink: 0;
            }

            img {
                width: 100%;
            }
        }

        .search-list {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                border-bottom: 1px solid #eee;

                a {
                    display: block;
                    padding: 1rem 0;
                    color: #000;
                }

            }
        }
    }



}

.directsearchsuggest-wrapper {
    display: flex;
    width: 100%;
}