/*###############################################

@Title: Page Compare
@Description:


###############################################*/

.compare-wrapper {
    position: fixed;
    top: 0rem;
    right: 0rem;
    bottom: 0rem;
    left: 0rem;
    background: #fff; //box-shadow: 0 0 100px #666;
    overflow: auto;
    background: #eee;
}

.compare-topbar {
    display: flex;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    padding: 1rem;
    height: 7rem;
    background: #eee;
    align-items: center;
    border-bottom: 1px solid #ccc;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    .compare-topbar-logo {
        img {
            height: 4rem;
        }
    }
    .compare-topbar-exit {}
}

.opc-compare {
    //background: rgba(#fff, 0.95);
    z-index: 100000;
    background: #eee;
    .compare-list {
        width: 100%;
        padding-top: 7rem;
    }
    .opc-message {
        margin-top: 8rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .compare-list-head {
        position: sticky;
        top: 0;
        z-index: 5;
    }
    .compare-row {
        display: flex;
        &.headrow {
            position: sticky;
            top: 0;
            z-index: 3;
        }
        &[data-op-id="Image"] {
            .compare-item {
                .value {
                    display: block;
                    width: 100%;
                    text-align: center;
                    img {
                        height: 12rem;
                        max-width: 100%;
                    }
                }
            }
        }
        &[data-op-id="Remove"] {
            .compare-item {
                .value {
                    display: block;
                    text-align: right;
                }
            }
        }
        .value.diff {
            
        }
        .compare-label {
            width: 30rem;
            flex-shrink: 0;
            position: sticky;
            left: 0;
            background: #eee;
            padding: 1rem;
            text-align: right;
            border-bottom: 1px solid #ccc;
            z-index: 2;
            select {
                margin-top: 0.5rem;
                @extend .opc-select;
            }
        }
        .compare-item {
            width: 30rem;
            flex-grow: 1;
            flex-shrink: 0;
            padding: 1rem;
            background: #fff; //border-right: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            &.dif {
                .value {
                    background: #ccc;
                }
            }
            &:nth-of-type(2) {}
            &:nth-of-type(4) {}
            &:nth-of-type(3) {}
            &:nth-of-type(5) {}
        }
    }
    .compare-group {
        .compare-row {
            &:first-of-type {
                //.compare-label,
                //.compare-item {border-top: 1px solid #000; }
            }
        }
    }
    .compare-group-title {
        position: relative;
        position: sticky;
        left: 0;
        width: 30rem;
        padding: 0;
        height: 4rem;
        cursor: pointer;
        .group-title-inner {
            position: absolute;
            left: 0;
            top: 0;
            height: 4rem;
            display: flex;
            align-items: center;
            padding: 0 0 0 1rem;
            width: calc(100vw - 20px);
            width: 100vw;
            background: #fff;
            @extend .h4;
            &::after {
                content: '';
                display: block;
                position: absolute;
                left: 100%;
                width: 2000px;
                top: 0;
                bottom: 0;
                background: #fff;
                display: none;
            } //background: #ccc;
        }
    }
}

.compare-topbar{
    &::after{
       content: "";
        width: 100%;
       //  margin-left: calc((100% - 100vw) / 2);
       //  margin-right: calc((100% - 100vw) / 2);
       //  padding-left: calc((100vw - 100%) / 2);
       //  padding-right: calc((100vw - 100%) / 2);
        box-sizing: content-box;
        opacity: .1;
        border: 0;
        margin-top: 0;
        background: linear-gradient(to bottom,rgba(0,0,0,0.8),rgba(0,0,0,0.1));
        height: 8px;
        margin-bottom: -8px;
        top: 100%;
        left: 0;
        right: 0;
        position: absolute;
        z-index: 1;
    }
  }
  #compareTable{
      margin-top: 7rem;
     > table{
        background: $white;
        margin-top: .9rem;
     }
     th{
         font-weight: 400;
     }
     .groupHeader{
         font-weight: 700;
         font-size: 1.8rem;
         a{
             color: $black;
         }
     }
  }

tr.name{
    .name1{
        display: block;
    }
    .color{
        >ul{
            margin: 0;
            padding: 0;
            display: inline;
            >li{
                display: inline-block;
                list-style-type: none;
                padding: 0 .2rem 0 0;
                &::after{
                    content: ',';
                }
            }
        }
        &::after{
            display: none;
        }
    }
}

.compare-wrapper {
    .opc-message {
        margin-top: 11rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

.differentValues{
    font-weight: 700;
    color: $brand-color-1;
}